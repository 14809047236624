import {Dialog, Transition} from '@headlessui/react'
import {Fragment, useEffect, useRef, useState} from 'react'
import {IoClose} from 'react-icons/io5';
import {SUCCESS200, SUCCESS201} from '../../../../constants/constants';
import moment from 'moment';
import {deleteExistingAccessList, getExistingAccessList, shareFolder} from '../../../../service/service.folder';
import toast from '../../../../utils/toast'
import {getUsersList} from '../../../../service/service.user';
import SwitchInput from '../inputs/SwitchInput';
import DefaultInput from '../inputs/DefaultInput';
import SearchInput from '../inputs/SearchInput';
import {validateEmail} from '../../../../utils/Utils';
import {IoMdAdd} from "react-icons/io";


export default function ShareModal({folder, isOpen, modalRef, closeModal}) {
    const [loading, setLoading] = useState(false);
    const [validity, setValidity] = useState(false);
    const [searchValue, setSearchValue] = useState("");
    const [expiry, setExpiry] = useState(moment().add(1, "d").format("YYYY-MM-DD"));
    const [data, setData] = useState([]);
    const [accessUsers, setAccessUsers] = useState([]);
    const [existingUsers, setExistingUsers] = useState([]);
    const [existingUsersLoading, setExistingUsersLoading] = useState([]);
    const [addMore, setAddMore] = useState(false);
    const [searchLoading, setSearchLoading] = useState(false);
    const delay = 1000;
    let timeoutId = useRef(null);

    useEffect(() => {
        fetchExistingUsers();
    }, []);

    const toggleButton = (name, value) => {
        setValidity(value)
    }


    const handleOnChange = (value) => {
        validateEmail(value)
        setSearchValue(value);
    }

    const fetchExistingUsers = () => {
        setExistingUsersLoading(true);
        getExistingAccessList(folder?.uuid)
            .then((res) => {
                console.log(res);
                if (res.data && res.data.status === SUCCESS200) {
                    setExistingUsers([...res.data.sharedUsers]);
                } else {
                    setExistingUsers([]);
                }
            })
            .catch((err) => {
                console.log(err);
                setExistingUsers([]);
            })
            .finally(() => setExistingUsersLoading(false));
    };

    const debounce = (func, delay) => {
        return (...args) => {
            clearTimeout(timeoutId.current);
            timeoutId.current = setTimeout(() => {
                func(...args);
            }, delay);
        };
    };


    const debouncedGetUsers = debounce((value) => {
        if (value.length > 0) {
            getUsersList(value)
                .then((res) => {
                    if (res.data && res.data.status === SUCCESS200) {
                        setData([...res.data.users]);
                        checkValidEmail()
                    } else {
                        setData([]);
                        checkValidEmail()
                    }
                    setSearchLoading(false)
                })
                .catch((err) => {
                    console.log(err);
                    setSearchLoading(false)
                    setData([]);
                });
        } else {
            setData([]);
            setSearchLoading(false)
        }
    }, delay);

    const checkValidEmail = () => {
        if (validateEmail(searchValue)) {
            const newObj = {
                name: searchValue.split('@')[0],
                email: searchValue,
                search: true
            }
            setData([...data, newObj])
        }
    }

    useEffect(() => {
        setSearchLoading(true)
        debouncedGetUsers(searchValue);
    }, [searchValue]);


    const addToAccess = (user) => {
        if (accessUsers.filter((accessUser) => accessUser.email === user.email).length > 0) {
            toast.error("User is already selected");
        } else {
            const newUserList = [...accessUsers, user];
            setAccessUsers(newUserList);
            setSearchValue("");
        }
    };

    const removeItem = (id, index) => {
        accessUsers.splice(index, 1);
        setAccessUsers([...accessUsers]);
    };

    const submitData = () => {
        if (accessUsers && accessUsers.length > 0) {
            setLoading(true);
            shareFolder(folder.uuid, validity ? {users: accessUsers, expire: expiry} : {users: accessUsers})
                .then((res) => {
                    if (res && res.data && (res.data.status === SUCCESS200 || res.data.status === SUCCESS201)) {
                        toast.success("Folder shared successfully");
                        fetchExistingUsers();
                        setAddMore(false);
                    } else {
                        toast.error("Unable to share folder");
                    }
                })
                .catch((err) => {
                    toast.error(
                        err && err.response && err.response.data && err.response.data.message
                            ? err.response.data.message
                            : "Unable to share folder"
                    );
                })
                .finally(() => setLoading(false));
        } else {
            toast.error("Please choose at least one user.");
        }
    };

    const removeExistingUser = (id, index) => {
        if (id) {
            deleteExistingAccessList(folder.uuid, {share_id: id});
        }
        const updatedUsers = [...existingUsers];
        updatedUsers.splice(index, 1);
        setExistingUsers(updatedUsers);
    };

    return (
        <Transition appear show={isOpen} as={Fragment}>
            <Dialog as="div" className="relative z-10" ref={modalRef} onClose={closeModal} open={isOpen}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-black/25"/>
                </Transition.Child>

                <div className="fixed inset-0 overflow-y-auto">
                    <div className="flex min-h-full items-center justify-center p-4 text-center">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 scale-95"
                            enterTo="opacity-100 scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 scale-100"
                            leaveTo="opacity-0 scale-95"
                        >
                            <Dialog.Panel
                                className={`w-full max-w-2xl w-2xl transform overflow-hidden rounded-2xl bg-theme-3 p-6  
                                text-left align-middle shadow-xl transition-all gap-7 flex flex-col`}>
                                <Dialog.Title
                                    as="h3"
                                    className="text-lg font-medium leading-6 text-white"
                                >
                                    <div className='flex justify-between items-center text-md'>
                                        <span>Share {folder.name || "Fetching..."}</span>
                                        <IoClose className='cursor-pointer text-[20px]' onClick={closeModal}/>
                                    </div>
                                </Dialog.Title>

                                {addMore ?
                                    <div className={"flex flex-col font-inter"}>
                                        <div className="flex flex-col gap-2">
                                            <ValidTillDate
                                                onChange={toggleButton}
                                                expiry={expiry}
                                                showInput={validity}
                                                onChangeForm={(name, value) => setExpiry(value)}
                                            />
                                            <SearchInput
                                                list={data}
                                                searchValue={searchValue}
                                                selected={{}}
                                                loading={searchLoading}
                                                setSelected={addToAccess}
                                                handleOnChange={handleOnChange}
                                            />
                                            <div className={"mt-3 flex flex-col gap-2"}>
                                                {accessUsers && accessUsers?.length > 0 ?
                                                    <div className={"flex flex-col gap-4"}>
                                                        <span className={"text-white text-[16px] font-medium"}>
                                                            Selected Members
                                                        </span>
                                                        <div className={"flex flex-col gap-2"}>
                                                            {accessUsers.map((item, index) => {
                                                                return <SharedUsers
                                                                    user={item}
                                                                    removeItem={removeItem}
                                                                    index={index}
                                                                />
                                                            })}
                                                        </div>
                                                    </div> : null}
                                            </div>
                                        </div>

                                        <div className="mt-4 flex justify-end gap-2">
                                            <ModalButton
                                                disabled={loading}
                                                name={loading ? "Loading..." : "Share"}
                                                onClick={submitData}
                                            />
                                            <ModalButton
                                                disabled={false}
                                                name={"Cancel"}
                                                onClick={(ev) => {
                                                    ev.stopPropagation()
                                                    setAddMore(false);
                                                }}
                                            />
                                        </div>
                                    </div>
                                    :
                                    <Fragment>
                                        <div
                                            className={"text-lg font-medium leading-6 text-white mb-5flex flex-col gap-2 font-inter"}>
                                            {existingUsersLoading ?
                                                <div className='flex justify-center items-center text-white my-4'>
                                                    Loading...
                                                </div>
                                                :
                                                existingUsers && existingUsers.length > 0 ?
                                                    <div className={"flex flex-col gap-4"}>
                                                        <span className={"text-white text-sm font-medium font-inter"}>
                                                            Members
                                                        </span>
                                                        <div className={"flex flex-col gap-2"}>
                                                            {existingUsers.map((item, index) => {
                                                                return (
                                                                    <SharedUsers
                                                                        user={item}
                                                                        removeItem={removeExistingUser}
                                                                        index={index}
                                                                    />
                                                                )
                                                            })}
                                                        </div>
                                                    </div>
                                                    :
                                                    <div className="my-2 pt-2 text-center text-white pb-4">
                                                        Not shared with anyone
                                                    </div>
                                            }
                                        </div>
                                        <div className="mt-4 flex justify-end gap-2">
                                            <ModalButton
                                                disabled={false}
                                                icon={<IoMdAdd/>}
                                                name={"Add More"}
                                                onClick={() => setAddMore(true)}
                                            />
                                            <ModalButton
                                                disabled={false}
                                                name={"Cancel"}
                                                onClick={closeModal}
                                            />

                                        </div>
                                    </Fragment>}
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition>
    )
}

const ModalButton = ({onClick, disabled, name, icon}) => {
    return (
        <button
            type="button"
            disabled={disabled}
            className={`flex items-center gap-1 rounded-sm px-4 py-2 text-sm font-medium focus:outline-none 
            focus-visible:ring-2 focus-visible:ring-transparent focus-visible:ring-offset-2 
            ${name === "Cancel" ? " bg-white hover:bg-white/90 text-theme-1" : " bg-theme-peach-200 hover:bg-theme-peach-100 text-white"}`}
            onClick={onClick}
        >
            {icon && <span className={"text-xl"}>{icon}</span>} <span>{name}</span>
        </button>
    )
}

const ValidTillDate = ({showInput, onChange, expiry, onChangeForm}) => {
    return (
        <div className={"w-full text-sm text-white font-medium"}>
            <div className={"w-full h-10 flex justify-between items-center"}>
                <span>Valid Till Date</span>
                <SwitchInput
                    onChange={onChange}
                    checked={showInput}
                />
            </div>
            {showInput &&
                <DefaultInput
                    value={expiry}
                    name={"expiry"}
                    onChange={onChangeForm}
                    type={"date"}
                    min={moment().add(1, "day").format("YYYY-MM-DD")}
                    placeholder={"expiry date"}
                    bg="bg-neutral"
                />
            }
        </div>
    )
}

const SharedUsers = ({user, index, showInput, removeItem}) => {
    return (
        <div key={user.email} className={"w-full text-xs text-white font-medium flex justify-between items-center"}>
            <div className={"flex gap-2 items-center"}>
                <div className={"w-10 h-10 rounded-full bg-gray-200"}>
                    <img
                        src={user?.id ? user.profileImage : "https://api.creatorstock.io/images/default-user.png"}
                        className='rounded-[50%] w-10 h-10'
                        alt={"thumb"}
                    />
                </div>
                <div>
                    <span> {user?.id ? user.name : user?.search ? user.name : user.email?.split('@')[0] || "N/A"}</span>
                    <p className={"text-xs"}>{user.email}</p>
                </div>
            </div>

            <div className={"flex gap-2 items-center"}>
                <span
                    className={"font-medium"}>
                    Expiry - {user?.expire ? moment(user.expire).format("MM/DD/YYYY") : 'Never'}
                </span>

                <button
                    onClick={() => removeItem(user.share_id, index)}
                    type="button"
                    className="w-7 h-7 text-xl rounded-[5px] bg-black/50 text-white hover:bg-black/60 focus:outline-none flex justify-center
                 items-center"
                >
                    <IoClose/>
                </button>
            </div>

        </div>
    )
}
