import React, { Fragment } from 'react';
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry";
import DataGrid from './DataGrid';
import DataListView from './DataList';

const DataCards = ({
    contents,
    deleteFile,
    editFile,
    archiveFile,
    restoreFile,
    downloadFile,
    viewContent,
    enabled,
    handleSelectedItems = () => null
}) => {
    return (
        !enabled ?
            <Fragment>
                <ResponsiveMasonry columnsCountBreakPoints={{ 350: 2, 780: 3, 900: 6 }}>
                    <Masonry className="grid !gap-4">
                        {contents.map((item, index) => {
                            return (
                                <DataGrid
                                    item={item}
                                    index={index}
                                    deleteFile={deleteFile}
                                    editFile={editFile}
                                    archiveFile={archiveFile}
                                    restoreFile={restoreFile}
                                    downloadFile={downloadFile}
                                    viewContent={viewContent}
                                    handleSelectedItems={handleSelectedItems}
                                />
                            );
                        })}
                    </Masonry>
                </ResponsiveMasonry>
            </Fragment>
            :
            <Fragment>
                <DataListView
                    data={contents}
                    deleteFile={deleteFile}
                    editFile={editFile}
                    archiveFile={archiveFile}
                    restoreFile={restoreFile}
                    downloadFile={downloadFile}
                    viewContent={viewContent}
                    handleSelectedItems={handleSelectedItems}
                />
            </Fragment>
    );
};


export default DataCards;
