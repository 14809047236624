import { useRef } from 'react';
import Thumb from '../../../../assets/images/no-thumb.jpg'
import { FiDownload } from "react-icons/fi";
import { FaCheck, FaEdit } from "react-icons/fa";
import { FaBoxArchive } from "react-icons/fa6"
import { RiDeleteBin5Fill } from "react-icons/ri";
import { MdOutlineSettingsBackupRestore } from "react-icons/md";
import { IoCheckbox } from "react-icons/io5";
import { MdCheckBoxOutlineBlank } from "react-icons/md";

const DataGrid = ({
    item,
    index,
    deleteFile,
    editFile,
    archiveFile,
    restoreFile,
    downloadFile,
    viewContent,
    handleSelectedItems = () => null
}) => {
    const videoRef = useRef(null);


    const stopMovie = (e) => {
        if (videoRef.current) {
            videoRef.current?.pause();
        }
    };
    const playMovie = (e) => {
        if (videoRef.current) {
            videoRef.current?.play();
        }

    };
    return (
        <>
            <div key={index} className={"group bg-border rounded-md overflow-hidden font-inter mt-2"} onMouseEnter={playMovie}
                onMouseLeave={stopMovie}>
                <div className={"relative overflow-hidden"}>
                {/* font-medium */}
                    <div
                        className="absolute border-0 bg-transparent w-[100px] left-2 top-2 "> 
                        {
                            item.meta?.deleted_at ? (
                                // <span
                                //     className="border-2 border-white rounded-sm bg-white px-1 text-sm"
                                //     style={{ color: "#22a714" }}
                                // >
                                //     Archived
                                // </span>
                                <span class="rounded-md bg-white py-1 px-2 text-xs text-yellow-600">
                                    Archived
                                </span>
                            ) :
                                item.meta?.approved === 1 ? (
                                    // <span
                                    //     className="border-2 border-white rounded-sm bg-white px-1 text-sm"
                                    //     style={{ color: "#22a714" }}
                                    // >
                                    //     Approved
                                    // </span>
                                    <span class="rounded-md bg-white py-1 px-2 text-xs text-yellow-600">
                                        Approved
                                    </span>
                                ) :
                                    item.meta.approved === 2 ? (
                                        // <span
                                        //     className="border-2 border-white rounded-sm bg-white px-1 text-sm"
                                        //     style={{ color: "#fa3e3e" }}
                                        // >
                                        //     Rejected
                                        // </span >
                                        <span class="rounded-md bg-white py-1 px-2 text-xs text-yellow-600">
                                            Rejected
                                        </span>
                                    ) : (
                                        // <span
                                        //     className="border-2 border-white rounded-sm bg-white px-1 text-[14px]"
                                        //     style={{ color: "#fc723b" }}
                                        // >
                                        //     Pending
                                        // </span>
                                        <span class="rounded-md bg-white py-1 px-2 text-xs text-yellow-600">
                                            Pending
                                        </span>
                                    )}

                    </div>
                    {item?.meta?.file_type == "video" ?
                        <video
                            ref={videoRef}
                            // preload="none"
                            loop={true}
                            playsInline={true}
                            muted={true}
                        >
                            <source src={item?.meta?.preview} type="video/mp4" />
                        </video>
                        :
                        <img
                            src={item?.meta?.image || Thumb}
                            onError={(e) => {
                                e.target.src = Thumb;
                            }}
                            alt={"thumb"}
                            className={"w-full h-[250px] md:h-[150px] lg:h-[160px] rounded-sm object-cover"}
                        />}
                    <OverlayButtons
                        item={item}
                        onClickSelect={(ev) => {
                            ev.stopPropagation()
                            handleSelectedItems(item)
                        }}
                        onClickCard={(ev) => {
                            ev.stopPropagation()
                            viewContent(item.id)
                        }}
                        onClickView={(ev) => {
                            ev.preventDefault();
                            ev.stopPropagation()
                            viewContent(item.id)
                        }}
                        onClickDownload={(ev) => {
                            ev.preventDefault();
                            ev.stopPropagation()
                            downloadFile(item.id)
                        }}
                        onClickEdit={(ev) => {
                            ev.preventDefault();
                            ev.stopPropagation()
                            editFile(item.id)
                        }}
                        onClickRestore={(ev) => {
                            ev.preventDefault();
                            ev.stopPropagation()
                            restoreFile(item.id)
                        }}
                        onClickArchive={(ev) => {
                            ev.preventDefault();
                            ev.stopPropagation()
                            archiveFile(item.id);
                        }}
                        onClickDelete={(ev) => {
                            ev.preventDefault();
                            ev.stopPropagation()
                            deleteFile(item.id);
                        }}
                    />

                </div>

                <div className={"flex flex-col gap-1 p-2"}>
                    <span className={"text-white text-sm font-medium"}>
                        {item?.meta?.name}
                    </span>
                    <div className={"flex flex-wrap gap-1 text-[10px] text-gray-400"}>
                        <span>
                            {item.meta?.date} |
                        </span>
                        <span>
                            {item.meta?.time} |
                        </span>
                        <span>
                            {item.meta?.size}
                        </span>
                    </div>
                </div>
            </div>
        </>
    );

};


const OverlayButtons = ({ item, onClickShare, onClickSelect, onClickCard, onClickView, onClickDownload, onClickEdit, onClickRestore, onClickArchive, onClickDelete }) => {
    return (
        <div onClick={onClickCard} className="absolute h-full w-full transition duration-300 ease-in-out hidden group-hover:flex flex-col
         left-0 right-0 top-0 bottom-0 bg-black/20 cursor-pointer">
            <div className='flex justify-between items-start'>

                <div className={"flex flex-col p-2 gap-2 justify-center items-start"}>
                    <span className={`w-6 h-6 flex justify-center cursor-pointer self-start items-center
                     text-white text-sm bg-theme-peach-100/90 hover:bg-theme-peach-100 rounded-sm border border-white`}
                        onClick={onClickSelect}
                    >
                        {item.isSelected ?
                            <FaCheck />
                            :
                            ""
                        }
                    </span>
                </div>
                <div className={"flex flex-col p-2 gap-2 justify-center items-end"}>
                    <span
                        onClick={onClickDownload}
                        className={"w-5 h-5 flex justify-center cursor-pointer items-center text-white text-xs " +
                            " bg-black/80 hover:bg-theme-peach-100 rounded-sm"}>
                        <FiDownload />
                    </span>
                    <span
                        onClick={onClickEdit}
                        className={"w-5 h-5 flex justify-center cursor-pointer" +
                            " items-center text-white text-xs bg-black/80 hover:bg-theme-peach-100 rounded-sm"}>
                        <FaEdit />
                    </span>
                    {item.meta?.deleted_at ?
                        <span
                            onClick={onClickRestore}
                            className={"w-5 h-5 flex justify-center cursor-pointer items-center text-white text-xs " +
                                " bg-black/80 hover:bg-theme-peach-100 rounded-sm"}>
                            <MdOutlineSettingsBackupRestore />
                        </span>
                        :
                        <span
                            onClick={onClickArchive}
                            className={"w-5 h-5 flex justify-center cursor-pointer items-center text-white text-xs " +
                                " bg-black/80 hover:bg-theme-peach-100 rounded-sm"}>
                            <FaBoxArchive />
                        </span>
                    }
                    <span
                        onClick={onClickDelete}
                        className={"w-5 h-5 flex justify-center cursor-pointer items-center text-white text-xs " +
                            " bg-black/80 hover:bg-theme-peach-100 rounded-sm"}>
                        <RiDeleteBin5Fill />
                    </span>
                </div>
            </div>

        </div>
    )
}

export default DataGrid;