import React from 'react';
import { GrFormView } from "react-icons/gr";
import { MdOutlineFileDownload } from "react-icons/md";
import { downloadFile } from '../../../../utils/Utils'
import TableDropdown from './components/tableDropdown';
import { RiDeleteBin5Fill } from 'react-icons/ri';
import { IoEye } from 'react-icons/io5';


const PurchaseTable = ({ contents }) => {
    const goToContent = (content) => {
        window.open(`https://creatorstock.io/content/${content.slug}/${content.id}`, '_blank');
    };

    const links = [
        { href: '/', label: 'View', page: true, icon: <IoEye />, onClick: goToContent,bg:"bg-indigo-500" },
        { href: '/', label: 'Download', icon: <RiDeleteBin5Fill />, page: false, onClick: downloadFile,bg:"bg-red-500" },
    ]

    function capitalizeFirstLetter(str) {
        return str.charAt(0).toUpperCase() + str.slice(1);
    }

    return (
        <div className="w-full">
            <table className="min-w-full bg-transparent text-gray-100 text-left border border-theme-5">
                <thead>
                    <tr className={"bg-theme-5 text-[14px]"}>
                        <th className="border-0 p-4 font-medium">Name</th>
                        <th className="border-0 p-4 font-medium">Type</th>
                        <th className="border-0 p-4 font-medium">Client</th>
                        <th className="border-0 p-4 font-medium">Status</th>
                        <th className="border-0 p-4 font-medium">Amount</th>
                        <th className="border-0 p-4 font-medium">Actions</th>
                    </tr>
                </thead>
                <tbody className={"font-inter font-medium"}>
                    {contents.map((row, index) => (
                        <tr key={row.id} className={'border-b border-theme-5'}>
                            <td className="border-0 p-4">
                                <div className={"flex flex-row items-center gap-2"}>
                                    <div className={"w-12 h-12 bg-theme-peach-100 rounded-md"}>
                                        {row?.icon}
                                        <img src={row.meta?.image} className='h-[50px] object-cover' width={100} alt="image" />
                                    </div>
                                    <div className={"flex flex-col gap-0"}>
                                        <span>{row.meta?.name}</span>
                                        <span className={"text-[12px]"}>{row.meta?.date} {row.meta.time}</span>
                                    </div>
                                </div>
                            </td>
                            <td className="border-0 px-4 py-2">{capitalizeFirstLetter(row.meta?.type)}</td>
                            <td className="border-0 px-4 py-2">{row.meta?.user?.name || "Not Found"}</td>
                            <td className="border-0 px-4 py-2">
                                <div className={`text-[12px] px-2 w-[100px] flex items-center rounded-sm bg-green-400`}>
                                    Purchased
                                </div>
                            </td>
                            <td className="border-0 px-4 py-2">{row.meta?.price}</td>
                            <td className="border-0 px-4 py-2">
                                {/* <TableDropdown page={true} links={links} data={row} /> */}
                                <div className="flex items-center gap-2">
                                    {links?.map((item, index) => {
                                        return (
                                            <button
                                                className={`group flex items-center rounded-[5px] px-2 py-2 text-sm text-white 
                                                ${item.bg}`}
                                                onClick={(ev) => {
                                                    ev.stopPropagation()
                                                    item.onClick(item?.page ? row : row?.id)
                                                }}
                                            >
                                                {item.icon}
                                            </button>
                                        )
                                    })}
                                </div>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
};

export default PurchaseTable;
