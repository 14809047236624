import React, { useEffect, useState, useRef } from 'react';
import { IoIosArrowDropdown } from "react-icons/io";
import { IoIosArrowDropup } from "react-icons/io";
import { IoClose } from "react-icons/io5";
import { FaCircleCheck } from "react-icons/fa6";

const UploadAccordion = ({ show = false, onClose = () => null, currentUploading }) => {
    const [isOpen, setIsOpen] = useState(false);
    const [totalUpload, setTotalUpload] = useState(0);

    const toggleAccordion = () => {
        setIsOpen(!isOpen);
    };

    useEffect(() => {
        setIsOpen(true);
        return () => {
            setIsOpen(false);
        };
    }, []);

    useEffect(() => {
        if (!currentUploading.length) return;
        calculateTotalUploading();
    }, [currentUploading]);

    const calculateTotalUploading = () => {
        const uploadedFiles = currentUploading.filter((item) => item.preview || item.progress === 100);
        setTotalUpload(uploadedFiles?.length);
    };

    return (show ?
        <div className="fixed z-[99] bottom-0 right-8 rounded-t-[15px] rounded-bl-none bg-[#F8FAFD] border-l border-gray-300 overflow-hidden w-[350px]">
            <div className="flex justify-between items-center p-4 cursor-pointer">
                <div className="flex items-center">
                    <span className="font-semibold">
                        {totalUpload === currentUploading.length ?
                            `${currentUploading?.length} uploads complete` :
                            `Uploading ${currentUploading?.length - totalUpload} items`
                        }
                    </span>
                </div>
                <div className="flex items-center">
                    <button
                        onClick={toggleAccordion}
                        className="text-gray-500 focus:outline-none ml-2"
                    >
                        {isOpen ? (
                            <IoIosArrowDropdown className="text-[25px]" />
                        ) : (
                            <IoIosArrowDropup className="text-[25px]" />
                        )}
                    </button>
                    <div className="ml-2 text-red-500">
                        <IoClose onClick={() => {
                            onClose();
                        }} className="text-[25px] cursor-pointer" />
                    </div>
                </div>
            </div>
            {isOpen && (
                <div className={`transition-all !duration-500 ease-in-out ${isOpen ? 'max-h-[300px]' : 'max-h-0'} bg-white overflow-y-auto overflow-x-hidden custom-scrollbar`}>
                    {currentUploading.length && currentUploading.map((item, index) => {
                        return <FileCard key={index} file={item} index={index} />;
                    })}
                </div>
            )}
        </div> : null
    );
};

const Spinner = ({ progress }) => {
    const percentage = progress ? 100 - progress : 100;
    return (
        <div className="relative h-5 w-8">
            <svg className="h-full w-full" width="100" height="100" viewBox="0 0 36 36" xmlns="http://www.w3.org/2000/svg">
                <circle cx="18" cy="18" r="16" fill="none" className="stroke-current text-gray-200 dark:text-gray-700" strokeWidth="4"></circle>
                <g className="origin-center -rotate-90 transform">
                    <circle cx="18" cy="18" r="16" fill="none" className="stroke-current text-blue-600 dark:text-blue-500" strokeWidth="4" strokeDasharray="100" strokeDashoffset={progress || 100}></circle>
                </g>
            </svg>
            <div className="flex items-center text-[10px] font-semibold">
                {`${percentage}%`}
            </div>
        </div>
    );
};

const FileCard = ({ file, index }) => {
    const startTimeRef = useRef(null);
    const [timeRemaining, setTimeRemaining] = useState(null);
    const [uploadSpeed, setUploadSpeed] = useState(0); // bytes per second

    useEffect(() => {
        if (file.progress < 100 && !file.preview && !startTimeRef.current) {
            startTimeRef.current = Date.now();
        }
    }, [file.progress]);

    useEffect(() => {
        if (startTimeRef.current && file.progress < 100 && file.progress > 0) {
            const interval = setInterval(() => {
                const elapsedTime = (Date.now() - startTimeRef.current) / 1000; // time in seconds
                const progressInBytes = (file.progress / 100) * file.file.size; // total bytes uploaded
                const uploadSpeedBytes = progressInBytes / elapsedTime; // bytes per second

                if (uploadSpeedBytes > 0) {
                    setUploadSpeed(uploadSpeedBytes);
                    const remainingBytes = file.file.size - progressInBytes;
                    const remainingTime = remainingBytes / uploadSpeedBytes;
                    setTimeRemaining(Math.max(0, remainingTime).toFixed(0));
                } else {
                    setTimeRemaining('Calculating...');
                }
            }, 1000);

            return () => clearInterval(interval);
        }
    }, [file.progress]);

    return (
        <div key={index} className='flex justify-between items-center p-3 hover:bg-[#EDEDED]'>
            <div title={file?.file?.name} className='text-[14px] truncate pr-2'>
                {file?.file?.name}

                {
                    file.progress < 100 && !file.preview ?
                        <div className="text-xs text-gray-600 ml-2">
                            {timeRemaining ? `${timeRemaining}s remaining` : 'Calculating...'}
                        </div>
                        :
                        ''
                }
            </div>

            <div className="flex items-center">
                {file.progress < 100 && !file.preview ? (
                    <Spinner progress={file.progress ? 100 - file?.progress : 100} />

                ) : (
                    <FaCircleCheck className='text-[18px] text-[green]' />
                )}
            </div>
        </div>
    );
};

export default UploadAccordion;
