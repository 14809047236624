import axios from "axios";
import { API_BASE_URL } from "../../constants/constants";
export function singIn(data) {
  try {
    return axios.post(`${API_BASE_URL}/login`, data);
  } catch (e) {
    console.log(e);
  }
}

export function singUp(data) {
  try {
    return axios.post(`${API_BASE_URL}/register`, data);
  } catch (e) {
    console.log(e);
  }
}

export function sendContactMessage(data) {
  try {
    return axios.post(`${API_BASE_URL}/contact/send`, data);
  } catch (e) {
    console.log(e);
  }
}

export function verifyUserOtp(data) {
  return axios.post(`${API_BASE_URL}/verify-account`, data);
}

export function resendVerificationCode(data) {
  return axios.post(`${API_BASE_URL}/verify-resend`, data);
}

export function forgotPasswordOtpGen(data) {
  return axios.post(`${API_BASE_URL}/password/forgot`, data);
}

export function verifyForgotPasswordOtp(data) {
  return axios.post(`${API_BASE_URL}/password/forgot/verify`, data);
}