import React from "react";
import Thumb from "../../../../assets/images/no-thumb.jpg"
import { IoEye } from "react-icons/io5";
import { FiDownload } from "react-icons/fi";
import { FaBoxArchive } from "react-icons/fa6";
import { RiDeleteBin5Fill } from "react-icons/ri";

const FileListView = ({ hide = false, data, openViewContent, archiveFile, deleteFile, restoreFile, downloadFile }) => {

    const links = [
        { href: '/', label: 'View', icon: <IoEye />, onClick: openViewContent, bg:"bg-indigo-500" },
        { href: '/', label: 'Download', icon: <FiDownload />, onClick: downloadFile, bg:"bg-green-500" },
        hide ? null : { href: '/', label: 'Delete', icon: <RiDeleteBin5Fill />, onClick: deleteFile,bg:"bg-red-500" },
        hide ? null : { href: '/', label: 'Archive', icon: <FaBoxArchive />, onClick: archiveFile,bg:"bg-theme-peach-100" },
    ].filter(Boolean);

    return (
        <div className="">
            <table className="min-w-full bg-transparent text-gray-100 text-left border border-theme-5">
                <thead>
                    <tr className={"bg-theme-5 text-[14px]"}>
                        <th className="border-0 p-4 font-medium">File</th>
                        <th className="border-0 p-4 font-medium max-w-[300px]">Name</th>
                        {/* <th className="border-0 p-4 font-medium">Tags</th> */}
                        <th className="border-0 p-4 font-medium">Status</th>
                        <th className="border-0 p-4 font-medium">Action</th>
                    </tr>
                </thead>
                <tbody className={"font-inter font-medium"}>
                    {data.map((item) => {
                        return <tr key={item?.id} className={'border-b border-theme-5'}>
                            <td className="border-0 p-4">
                                <div className={"flex flex-row items-center gap-2 w-12 h-12"}>
                                    <img
                                        onError={(e) => {
                                            e.target.src = Thumb;
                                        }}
                                        className={"w-full h-full object-cover rounded-md"} src={item.meta?.image || Thumb}
                                        alt={"thumb"} />
                                </div>
                            </td>
                            <td className="border-0 px-4 py-2 truncate max-w-[300px]">{item.meta?.name}</td>
                            {/* <td className="border-0 px-4 py-2">
                                <div className={"flex gap-2"}>
                                    {item.tags?.length ? item.tags?.map((item, index) => {
                                        return (
                                            <div key={index}
                                                className={"flex items-center bg-theme-3 px-2 h-6 rounded-sm text-[12px]"}>
                                                {item}
                                            </div>
                                        )
                                    }) : <div>N/A</div>}
                                </div>
                            </td> */}
                            <td className="border-0 px-4 py-2 truncate">{item.meta?.published === 1 ? (
                                <span
                                    className="border-2 border-[#ec4899] bg-[#ec4899] px-1 text-[14px] rounded"
                                    style={{ color: "#000000" }}
                                >
                                    Processed
                                </span>
                            ) : (
                                <span
                                    className="border-2 border-[#f9a8d4] rounded bg-[#f9a8d4] px-1 text-[14px]"
                                    style={{ color: "#000000" }}
                                >
                                    Processing
                                </span>
                            )}</td>
                            <td className="border-0 px-4 py-2 truncate">
                                <div className="flex items-center gap-2">
                                    {links?.map((item, index) => {
                                        return (
                                            <button
                                                className={`group flex items-center rounded-[5px] px-2 py-2 text-sm text-white 
                                                ${item.bg}`}
                                                onClick={(ev) => {
                                                    ev.stopPropagation()
                                                    item.onClick(item?.page ? data : data?.id)
                                                }}
                                            >
                                                {item.icon}
                                            </button>
                                        )
                                    })}
                                </div>
                            </td>
                        </tr>
                    })}

                </tbody>
            </table>
        </div>
    )
}

export default FileListView;
