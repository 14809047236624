import React, { useContext, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import MainLoader from "../../main/components/loading/MainLoader";
import { getCurrentUser } from "../../../service/service.user";
import { UserContext } from '../../../context/user.context'
import { SUCCESS200, SUCCESS201 } from "../../../constants/constants";
import localStorage from "../../../utils/localStorage";

export default function SingleSignOn() {
  const params = useParams();
  const navigate = useNavigate()
  const { login } = useContext(UserContext);

  useEffect(() => {
    if (params.token) {
      localStorage.setItem("token", params.token);
      setTimeout(() => {
        getCurrentUser()
          .then((res) => {
            if ((res.status === SUCCESS200 || res.status === SUCCESS201) && res.data && res.data.status === SUCCESS200) {
              login({ user: res.data?.data, access_token: params.token });
              navigate("/")
            } else {
              window.location.href = window.location.host.includes("localhost:")
                ? `http://localhost:3000/?redirect=http://localhost:3000`
                : `https://auth.creatorstock.io/?redirect=https://cloud.creatorstock.io`;
            }
          })
          .catch((err) => {
            console.log(err)
            // window.location.href = window.location.host.includes("localhost:")
            //   ? `http://localhost:3000/?redirect=http://localhost:3000`
            //   : `https://auth.creatorstock.io/?redirect=https://cloud.creatorstock.io`;
          });
      }, 1000)

    } else {
      window.location.href = window.location.host.includes("localhost:")
        ? `http://localhost:3000/?redirect=http://localhost:3000`
        : `https://auth.creatorstock.io/?redirect=https://cloud.creatorstock.io`;
    }
  }, [params.token]);

  return (
    <div style={{ width: "100%", height: "100vh", background: "#ffffff" }} className="flex justify-center align-center">
      <MainLoader />
    </div>
  );
}
