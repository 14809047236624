import React from 'react';

function MainLoader({ height = 300 }) {
    return (
        <div className={`h-[${height}px] flex flex-col justify-center items-center animate-[fadeIn_0.2s]`}>
            <div
                className={"inline-block h-8 w-8 animate-spin rounded-full border-4 border-solid border-current border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite] text-white"}
                role="status">
                <span
                    className="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]"
                >Loading...</span>
            </div>
        </div>
    );
}

export default MainLoader;
