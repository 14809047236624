import React, { createContext, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import Axios from "../configs/axios.config";
import { API_BASE_URL } from "../constants/constants";
import { categories, getStorage } from "../service/service.user";

const chunkSize = 1048576 * 3;

const allowedTypes = ["image", "video", "application"];

export const UploaderContext = createContext({
  addFile: () => { },
  filesUploading: {},
  updateFileDetails: () => { },
  getFileDetails: () => { },
  removeFile: () => { },
  resetFileUploading: () => { },
  showAccordion: false,
  storageData: null
});

const INIT_UPLOAD_PARAMS = {
  requestId: null,
  fileSize: 0,
  error: "",
  fileGuid: "",
  beginingOfTheChunk: 0,
  endOfTheChunk: chunkSize,
  chunkSize,
  progress: 0,
  file: {},
  caption: "",
  description: "",
  tags: [],
  price: 0,
  is_paid: false,
  preview: "",
  chunkCount: 0,
  chunksUploaded: 0,
};

export const UploaderProvider = ({ children }) => {
  const [filesUploading, setFilesUploading] = useState({});
  const [showAccordion, setShowAccordion] = useState(false);
  const [storageData, setStorageData] = useState(null);
  const [categoriesData, setCategoriesData] = useState([]);

  const resetFileUploading = () => {
    setFilesUploading({})
  }

  const fetchStorage = () => {
    getStorage().then((res) => {
      if (res && res?.data) {
        setStorageData(res.data)
      }
    }).catch((err) => {
      console.log("err", err)
    })
  }

  const addFile = async (_file, parent_id) => {
    const expType = _file.type.split("/");
    console.log("-------", expType);
    if (!allowedTypes.includes(expType[0])) return;

    try {
      const requestData = await Axios.post(`${API_BASE_URL}/content/request`, parent_id ? { parent_id, file_name: _file.name } : {});
      if (!requestData.data.request_id) return;

      const fileData = {
        ...INIT_UPLOAD_PARAMS,
        file: _file,
        requestId: requestData.data.request_id,
      };
  
      fileData.fileSize = _file.size;
  
      const _totalCount =
        _file.size % chunkSize == 0
          ? _file.size / chunkSize
          : Math.floor(_file.size / chunkSize) + 1;
  
      fileData.chunkCount = _totalCount;
  
      const _fileID = requestData.data.request_id + "." + _file.name;
  
      fileData.fileGuid = _fileID;
  
      filesUploading[fileData.requestId] = fileData;
      setFilesUploading({ ...filesUploading });
      setShowAccordion(true)
      // console.log("data -------", _file.slice(fileData.beginingOfTheChunk, fileData.endOfTheChunk))
      uploadChunk({
        requestId: fileData.requestId,
        chunk: _file,
        chunksUploaded: fileData.chunksUploaded,
        chunkCount: fileData.chunkCount,
        fileGuid: fileData.fileGuid,
      });
    } catch (error) {
      console.log(error);
    }
  };

  const handleCloseAccordion = () => {
    setShowAccordion(!showAccordion)
  }

  const uploadChunk = async ({
    requestId,
    chunk,
    chunksUploaded,
    chunkCount,
    fileGuid,
  }) => {
    try {
      const formData = new FormData();
      formData.append("name", fileGuid);
      formData.append("chunk", chunksUploaded);
      formData.append("chunks", chunkCount);
      formData.append("file", chunk);
      const response = await Axios.post(
        `${API_BASE_URL}/content/chunk/${requestId}`,
        formData,
        {
          headers: { "Content-Type": "multipart/form-data" },
          // headers: { "Content-Type": "application/pdf;" }
        }
      );
      const data = response.data;
      if (data.status) {
        if (filesUploading[requestId].chunksUploaded + 1 == chunkCount) {
          filesUploading[requestId].preview = data.path;
          setFilesUploading({ ...filesUploading });
        } else {
          let percentage = ((chunksUploaded + 1) / chunkCount) * 100;
          const nextChunkStart = filesUploading[requestId].endOfTheChunk;
          const nextChunkEnd = Math.min(
            filesUploading[requestId].endOfTheChunk +
            filesUploading[requestId].chunkSize,
            filesUploading[requestId].fileSize
          );
          filesUploading[requestId].progress = percentage;
          filesUploading[requestId].beginingOfTheChunk = nextChunkStart;
          filesUploading[requestId].endOfTheChunk = nextChunkEnd;
          filesUploading[requestId].chunksUploaded =
            filesUploading[requestId].chunksUploaded + 1;
          setFilesUploading({ ...filesUploading });
          uploadChunk({
            requestId: requestId,
            chunk: filesUploading[requestId].file.slice(
              nextChunkStart,
              nextChunkEnd
            ),
            chunksUploaded: chunksUploaded + 1,
            chunkCount,
            fileGuid,
          });
        }
      } else {
        console.log("Error Occurred:", data.errorMessage);
      }
    } catch (error) {
      filesUploading[requestId].error = error.response.data.message;
      setFilesUploading({ ...filesUploading });
    }
  };

  const updateFileDetails = (index, key, value) => {
    filesUploading[index][key] = value;
    setFilesUploading({ ...filesUploading });
  };

  const removeFile = (index) => {
    if (Object.keys(filesUploading).length > 0) {
      const newFilesUploading = filesUploading;
      delete newFilesUploading[index];
      setFilesUploading({ ...newFilesUploading });
    }
  };

  const getFileDetails = () => {
    const textDetails = Object.values(filesUploading).map((file) => {
      return {
        requestId: file.requestId,
        caption: file.caption,
        description: file.description,
        is_paid: file.is_paid,
        tags: file.tags,
        category_id: file.category_id
      };
    });
    return textDetails;
  };
  const categoriesList = async () => {
    await categories().then((res) => {
      setCategoriesData(res.data.categories.map((category) => ({ name: category.name + category.emoji, id: category.id })))
    })
      .catch((err) => console.log(err.response.data))
      .finally(() => console.log('final'));
  };

  return (
    <UploaderContext.Provider
      value={{
        addFile,
        filesUploading,
        resetFileUploading,
        updateFileDetails,
        getFileDetails,
        removeFile,
        showAccordion,
        fetchStorage,
        storageData,
        handleCloseAccordion,
        categoriesList,
        categoriesData,

      }}
    >
      {children}
    </UploaderContext.Provider>
  );
};
