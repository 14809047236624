import React, { useEffect } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { Fragment, useState } from 'react'
import { IoClose } from 'react-icons/io5'
import { RiDeleteBinLine } from 'react-icons/ri'
import Button from '../buttons/Button'
import toast from '../../../../utils/toast'
import { assignCollection, createCollection, getCollections, updateCollection } from '../../../../service/service.collection'
import { SUCCESS200, SUCCESS201 } from '../../../../constants/constants'
import SelectBox from '../inputs/SelectBox'
import MainLoader from '../loading/MainLoader'
import NotFound from '../emptyPage/NotFound'
import DefaultInput from '../inputs/DefaultInput'
import TextareaInput from '../inputs/TextareaInput'
import { TbCoinPound } from 'react-icons/tb'

const AddToCollectionModal = ({ isOpen, closeModal, handleDeleteSelectedItems, handleResetSelected, selectedItems = [] }) => {
    const [saving, setSaving] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [collectionList, setCollectionList] = useState([])
    const [selected, setSelected] = useState(null)
    const [error, setError] = useState(null)
    const [showCollection, setShowCollection] = useState(false);

    const toggleCollection = () => {
        setShowCollection(!showCollection)
    }

    const handleSelect = (value) => {
        setSelected(value)
        if (value) {
            setError(null)
        }
    }

    const handleDelete = (item) => {
        const isLastItem = selectedItems.length === 1;
        if (isLastItem) {
            toast.error("Minimum one item required for collection.");
            return
        }
        handleDeleteSelectedItems(item);
    }

    useEffect(() => {
        loadContents();
    }, []);

    const loadContents = async () => {
        setIsLoading(true);
        await getCollections()
            .then((res) => {
                if (
                    (res.status === SUCCESS200 || res.status === SUCCESS201) &&
                    res.data &&
                    res.data.status === SUCCESS200 &&
                    res.data.collections
                ) {
                    const modifiedResponse = res.data.collections.map((item) => ({
                        name: item.name,
                        id: item.id,
                        uuid: item.uuid,
                        data: item
                    }));
                    setCollectionList(modifiedResponse);
                }
            })
            .catch((err) => console.log(err))
            .finally(() => {
                setIsLoading(false);
            });
    };

    const submitDetails = async () => {
        if (!selected) {
            setError("This field is required.")
            return
        }
        setSaving(true)
        const payload = selectedItems.map((item) => {
            return {
                id: item.meta?.id,
                name: item.meta?.name
            }
        })
        await assignCollection(selected.id, { "contents": payload })
            .then((res) => {
                if (
                    (res.status === SUCCESS200 || res.status === SUCCESS201) &&
                    res.data &&
                    res.data.status === SUCCESS200
                ) {
                    toast.success(res.data.message)
                    setSaving(false)
                    handleResetSelected()
                    closeModal()
                }
            })
            .catch((err) => {
                toast.error("Something went wrong!")
            })
            .finally(() => {
                setSaving(false);
            });
    }

    return (
        <Transition appear show={isOpen} as={Fragment}>
            <Dialog as="div" className="relative z-10" onClose={closeModal} open={isOpen}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-black/80" />
                </Transition.Child>

                <div className="fixed inset-0 overflow-y-auto">
                    <div className="flex min-h-full items-center justify-center p-4 text-center">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 scale-95"
                            enterTo="opacity-100 scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 scale-100"
                            leaveTo="opacity-0 scale-95"
                        >
                            <Dialog.Panel
                                className="w-full max-w-md transform overflow-hidden rounded-2xl bg-theme-1 p-6 text-left align-middle shadow-xl transition-all">
                                <Dialog.Title
                                    as="h3"
                                    className="text-lg font-medium leading-6 text-white pb-4"
                                >
                                    <div className='flex justify-between items-center'>
                                        <span>{showCollection ? "Create Collection" : "Add to Collection"}</span>
                                        <IoClose className='cursor-pointer text-[20px]' onClick={closeModal} />
                                    </div>
                                </Dialog.Title>

                                {isLoading ? (
                                    <div className='flex justify-center items-center text-white mt-4 h-[30vh]'>
                                        <MainLoader />
                                    </div>
                                ) : collectionList && collectionList.length ?
                                    <Fragment>
                                        <div className="mt-2">
                                            <SelectBox
                                                selected={selected || "Select Collection"}
                                                onChange={handleSelect}
                                                list={collectionList}
                                            />
                                            {error ? <span className='text-red-500 text-[11px] mt-[-5px]'>{error}</span> : null}
                                        </div>
                                        <div className='mt-4'>
                                            <div className='text-white mb-3'>Selected content</div>
                                            <div className='border-b-[1px] border-white mb-3'></div>
                                            <div className='max-h-[250px] overflow-y-auto overflow-x-hidden custom-scrollbar'>
                                                {selectedItems && selectedItems?.length ? selectedItems.map((item) => {
                                                    return <Items item={item} handleDelete={handleDelete} />
                                                }) : null}
                                            </div>
                                        </div>

                                        <div className="mt-4 flex justify-end gap-2">
                                            <ModalButton
                                                onClick={() => submitDetails()}
                                                disabled={saving}
                                                name={saving ? 'Processing...' : 'Add to Collection'}
                                            />
                                            <ModalButton
                                                onClick={closeModal}
                                                name={"Cancel"}
                                            />
                                        </div>
                                    </Fragment> : !showCollection ? <NoData handleClick={toggleCollection} /> : null
                                }
                                {showCollection ?
                                    <CollectionTable loadContents={loadContents} closeModal={toggleCollection} /> : null}

                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition>
    )
}


const initialValue = {
    name: '',
    description: '',
    price: 0,
};

const CollectionTable = ({ closeModal, loadContents }) => {
    const [saving, setSaving] = useState(false);
    const [details, setDetails] = useState(initialValue);
    const [detailsError, setDetailsError] = useState({});

    const submitDetails = () => {
        if (!details.name) {
            setDetailsError({ name: "Name filed is requried" })
            return
        }
        setSaving(true);
        createCollection(details)
            .then((res) => {
                toast.success(res.data.message);
                setDetails(initialValue);
                setSaving(false)
                loadContents()
                closeModal()
            })
            .catch((err) => toast.error(err.response.data.message))
            .finally(() => setSaving(false));
    }

    const handleCheckValidation = (name, value) => {
        if (name === "name" && value) {
            setDetailsError({})
        }
    }

    const handleChange = (name, value) => {
        setDetails({ ...details, [name]: value })
        handleCheckValidation(name, value)
    }

    return (<>
        <div className="mt-2 flex flex-col gap-2">
            <DefaultInput
                className="text-white"
                bg={"bg-theme-3"}
                value={details.name}
                name="name"
                onChange={handleChange}
                placeholder={"Collection Name"}
            />
            {detailsError?.name ? <span className='text-red-500 text-[11px] mt-[-5px]'>{detailsError.name}</span> : null}
            <TextareaInput
                className="text-white"
                bg={"bg-theme-3"}
                value={details.description}
                name="description"
                onChange={handleChange}
                placeholder={"Description..."}
            />
            <DefaultInput
                className="text-white"
                bg={"bg-theme-3"}
                icon={<TbCoinPound />}
                value={details.price}
                name="price"
                type='number'
                onChange={handleChange}
                placeholder={"Price"}
            />
            <span className={'text-[12px] text-white'}>Add content to collection by navigating to the My content section</span>
        </div>

        <div className="mt-4 flex justify-end gap-2">
            <Button
                onClick={() => submitDetails()}
                disabled={saving}
                name={saving ? 'Processing...' : 'Create Collection'}
            />
            <Button
                onClick={closeModal}
                name={"Cancel"}
            />
        </div>
    </>
    )
}

const NoData = ({ handleClick }) => {
    return <div>
        <div className="h-[250px] flex flex-col justify-center items-center text-white text-[14px]">
            No collections found!
            <p className='mt-1 text-[13px] mb-5 px-2 text-center'>Click on the 'New Collection' button to add some. </p>
            <Button onClick={handleClick} name="Create New collection" />
        </div>
    </div>
}

const Items = ({ item, handleDelete }) => {
    return (
        <div className='flex justify-between items-center px-2 mb-1.5'>
            <div className='flex items-center gap-2'>
                < div className='bg-black/10 rounded-md'>
                    <img width={50} height={50} className='cover bg-red-50 w-[50px] h-[50px] rounded-md' src={item.meta?.image} />
                </div>
                <p className='text-white'>
                    {item.meta?.name || "No caption available"}
                </p>
            </div>
            <Button
                onClick={() => handleDelete(item)}
                icon={<RiDeleteBinLine className='text-[18px]' />} />
        </div>
    )
}

const ModalButton = ({ onClick, disabled, name }) => {
    return (
        <button
            type="button"
            disabled={disabled}
            className={`inline-flex justify-center rounded-md px-4 py-2 text-sm font-medium focus:outline-none 
            focus-visible:ring-2 focus-visible:ring-transparent focus-visible:ring-offset-2 
            ${name === "Cancel" ? " bg-white hover:bg-white/90 text-theme-1" : " bg-theme-peach-200 hover:bg-theme-peach-100 text-white"}`}
            onClick={onClick}
        >
            {name}
        </button>
    )
}

export default AddToCollectionModal