const TextareaInput = ({ label, show = false, type, placeholder, bg, name, onChange, value }) => {
    const handleTextareaChange = (event) => {
        onChange(name, event.target?.value)
    };
    return (
        <div className={ "flex flex-col gap-2"}>
            {label && <label>{label}</label>}
            <textarea
                id="message" rows="4"
                value={value}
                onChange={handleTextareaChange}
                className={`w-full min-h-10 border border-border ${bg ? bg : "bg-theme-2 "} rounded-md p-2 text-sm text-white focus:outline-none`}
                placeholder={placeholder}>
            </textarea>

        </div>
    )
}
export default TextareaInput;
