import React, { useContext } from 'react';
import { MdFolderShared } from "react-icons/md";
import { Link, useParams } from 'react-router-dom';
import { UserContext } from '../../../context/user.context';

const Folders = ({ key, item }) => {
    const params = useParams();
    const { user } = useContext(UserContext);
    return (
        <div key={key} className={"bg-theme-5 rounded-md flex justify-between items-center text-white text-[14px]"}>
            <Link to={!user.isAuthenticated ? '/auth/login': `/shared/folder/${params.id ? `${params.id}/${item.id}` : item.id}`} className='w-full'>
                <span className={"flex items-center h-12 flex-1 cursor-pointer pl-4 gap-2"}>
                    <span className='w-10 h-10 text-[35px] justify-center items-center'>
                        {item.meta?.svg}
                    </span>
                    {item?.folder?.name || "N/A"}
                </span>
            </Link>
        </div>
    );
};

export default Folders;
