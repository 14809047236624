import React, { useContext, useEffect, useState } from 'react';
import PageHeading from '../components/pageHeading/PageHeading';
import UploadInput from './components/UploadInput';
import { useNavigate } from 'react-router-dom';
import { UploaderContext } from '../../../context/uploader.context';
import { submitContent } from '../../../service/service.content';
import toast from '../../../utils/toast';
import { IoMdAdd } from 'react-icons/io';
import Thumb from '../../../assets/images/no-thumb.jpg';

const UploadComp = (props) => {
    const navigate = useNavigate();
    const { addFile, filesUploading, getFileDetails, categoriesList, resetFileUploading } = useContext(UploaderContext);
    const [uploading, setUploading] = useState(true);
    const [saving, setSaving] = useState(false);

    useEffect(() => {
        categoriesList();
    }, []);

    const handleFileUpload = (files) => {
        for (let index = 0; index < files.length; index++) {
            addFile(files[index], props.modal ? props.id : null);
        }
        setUploading(false); // Set uploading to false when file upload starts
    };

    useEffect(() => {
        if (props.modal && Object.values(filesUploading).length > 0) {
            Object.values(filesUploading).map((file, index) => {
                if (!(file.progress < 100 && !file.preview) && props.uploadedFiles !== undefined && !props.uploadedFiles.includes(file.request_id)) {
                    props.uploadedFiles.push(file.request_id);
                    props.setUploadedFiles([...props.uploadedFiles]);
                }
            });

            if (props.uploadedFiles && Object.values(filesUploading).length === props.uploadedFiles.length) {
                setTimeout(() => {
                    props.toggle();
                }, 2000);
            }
        }
    }, [filesUploading]);

    const hanldeDetailsSubmit = () => {
        setSaving(true);
        const fileDetails = getFileDetails();
        submitContent({ files: fileDetails })
            .then((res) => {
                resetFileUploading();
                toast.success('Content has been uploaded!');
                navigate('/stock/content-manager');
            })
            .catch((err) => console.log(err))
            .finally(() => setSaving(false));
    };

    return (
        <div>
            <PageHeading heading={'Uploading Queue'} profile />
            <div className="flex w-full h-[calc(100vh-70px)] p-4">
                {uploading ? <UploadInput handleFileUpload={handleFileUpload} /> : <UploadFiles />}
            </div>
        </div>
    );
};

const UploadFiles = ({}) => {
    return (
        <div className={'flex flex-col items-start gap-4 font-inter font-medium'}>
            <div>
                <span className={`flex items-center gap-1 px-4 border border-green-600 bg-green-50 hover:bg-green-100
                 cursor-pointer text-green-600 h-10 rounded-md`}>
                    <IoMdAdd className={'text-xl'} /> Upload
                </span>
            </div>
            <div className={'grid grid-cols-3 md:grid-cols-6 lg:grid-cols-8 gap-4'}>
                <FileCard />
                <FileCard />
                <FileCard />
                <FileCard />
                <FileCard />
                <FileCard />
                <FileCard />
                <FileCard />
                <FileCard />
                <FileCard />
                <FileCard />
            </div>
        </div>
    );
};

const FileCard = ({}) => {
    return (
        <div className={'bg-border rounded-md overflow-hidden'}>
            <img src={Thumb} alt={'thumb'} />
            <div className={'flex flex-col gap-1 p-2 text-white text-xs'}>
                <span>No Thumb</span>
            </div>
        </div>
    );
};

export default function Upload(props) {
    return <UploadComp {...props} />;
}
