import React, { useContext, useEffect, useState } from "react";
import DrawerContainer from "../../../components/drawer/drawerContainer";
import { Link } from "react-router-dom";
import { UserContext } from "../../../../context/user.context";
import defaultImage from '../../../../assets/images/user.jpg'
import Logo from '../../../../assets/images/logo.svg'
import ProfileDropdown from "../buttons/profileDropdown";

const PageHeading = ({ heading, profile }) => {
    const [theme, setTheme] = useState("light");
    const { user } = useContext(UserContext)

    useEffect(() => {
        if (theme === "dark") {
            document.documentElement.classList.add("dark");
        } else {
            document.documentElement.classList.remove("dark");
        }
    }, [theme]);

    const onSwitchTheme = () => {
        setTheme(theme === 'dark' ? 'light' : 'dark');
    }

    return (
        <div className={"w-full h-[70px] border-b border-black/50 flex justify-between items-center text-white px-4"}>
            <div className={"flex gap-2"}>
                <Link to={"/"}>
                    <img className={"w-10 lg:hidden"}
                        src={Logo}
                        alt={"svg"}
                    />
                </Link>
                <h3 className="font-normal text-lg">{heading}</h3>
            </div>

            <div className={"items-center gap-4 hidden lg:flex"}>

                {profile ?
                    <ProfileDropdown
                        user={user}
                        defaultImage={defaultImage}
                    />
                    :
                    <div>
                    </div>
                }
            </div>
            <div className={"lg:hidden flex"}>
                <DrawerContainer />
            </div>
        </div>
    )
}
export default PageHeading;
