import React, { useRef } from "react";
import Thumb from "../../../../assets/images/no-thumb.jpg";
import { FiDownload, FiEye } from "react-icons/fi";
import { FaCheck, FaEdit } from "react-icons/fa";
import { FaBoxArchive } from "react-icons/fa6";
import { RiDeleteBin5Fill } from "react-icons/ri";
import { MdOutlineSettingsBackupRestore } from "react-icons/md";
import { IoCheckbox } from "react-icons/io5";
import { MdCheckBoxOutlineBlank } from "react-icons/md";
import Button from "../../components/buttons/Button";

const DataListView = ({
    hide = false,
    data,
    viewContent,
    archiveFile,
    deleteFile,
    restoreFile,
    downloadFile,
    editFile,
    handleSelectedItems = () => null,
}) => {

    return (
        <div className="">
            <table className="min-w-full bg-transparent text-gray-100 text-left border border-theme-5">
                <thead>
                    <tr className={"bg-theme-5 text-[14px]"}>
                        <th className="border-0 p-4 font-medium w-[100px]">File</th>
                        <th className="border-0 p-4 font-medium max-w-48 w-48">Name</th>
                        <th className="border-0 p-4 font-medium">Date</th>
                        <th className="border-0 p-4 font-medium">Status</th>
                        <th className="border-0 p-4 font-medium">Action</th>
                    </tr>
                </thead>
                <tbody className={"font-inter font-medium"}>
                    {data.map((item, index) => (
                        <tr key={item?.id} className={'border-b border-theme-5'}>
                            <td className="border-0 p-4 w-[100px]" >
                                <div className="flex gap-1">
                                    <div className={"flex flex-col p-2 gap-2 justify-center items-start"}>
                                        <span
                                            className={`w-6 h-6 flex justify-center cursor-pointer self-start items-center 
                                                text-white text-sm bg-theme-peach-100/90 hover:bg-theme-peach-100 rounded-sm border border-white`
                                            }
                                            onClick={(ev) => {
                                                ev.stopPropagation()
                                                handleSelectedItems(item)
                                            }}
                                        >
                                            {item.isSelected ? <FaCheck /> : ""}
                                        </span>
                                    </div>
                                    <div className={"flex flex-row items-center gap-2 w-12 h-12"}>

                                        <img
                                            src={item?.meta?.image || Thumb}
                                            onError={(e) => {
                                                e.target.src = Thumb;
                                            }}
                                            alt={"thumb"}
                                            className={"w-full h-full object-cover rounded-md"}
                                        />
                                    </div>
                                </div>
                            </td>
                            <td className="border-0 px-4 py-2 truncate max-w-48 w-48">
                                {item.meta?.name}
                            </td>
                            <td className="border-0 px-4 py-2 ">
                                <div className={"flex flex-wrap gap-1 text-[10px] text-gray-400 "}>
                                    <span>
                                        {item.meta?.date} |
                                    </span>
                                    <span>
                                        {item.meta?.time} |
                                    </span>
                                    <span>
                                        {item.meta?.size}
                                    </span>
                                </div>
                            </td>
                            <td className="border-0 px-4 py-2 truncate">
                                {item.meta?.deleted_at ? (
                                    <span
                                        className="border-2 border-white rounded-sm bg-white px-1 text-sm"
                                        style={{ color: "#22a714" }}
                                    >
                                        Archived
                                    </span>
                                ) : item.meta?.approved === 1 ? (
                                    <span
                                        className="border-2 border-[#22a714] rounded-sm bg-[#22a714] px-1 text-[14px]"
                                        style={{ color: "#ffffff" }}
                                    >
                                        Approved
                                    </span>
                                ) : item.meta?.approved === 2 ? (
                                    <span
                                        className="border-2 border-[#fa3e3e] rounded-sm bg-[#fa3e3e] px-1 text-[14px]"
                                        style={{ color: "#ffffff" }}
                                    >
                                        Rejected
                                    </span>
                                ) : (
                                    <span
                                        className="border-2 border-[#fc723b] rounded-sm bg-[#fc723b] px-1 text-[14px]"
                                        style={{ color: "#ffffff" }}
                                    >
                                        Pending
                                    </span>
                                )}
                            </td>
                            <td className="border-0 px-4 py-2">
                                <div className="flex gap-1">
                                    <Button classnames="!bg-indigo-500 !hover:text-white" icon={<FiEye />}
                                        onClick={(ev) => {
                                            ev.preventDefault();
                                            ev.stopPropagation();
                                            viewContent(item.id);
                                        }}
                                    />
                                    <Button classnames="!bg-green-500 !hover:text-white" icon={<FiDownload />}
                                        onClick={(ev) => {
                                            ev.preventDefault();
                                            ev.stopPropagation();
                                            downloadFile(item.id);
                                        }}
                                    />
                                    <Button classnames="!bg-yellow-500 !hover:text-white" icon={<FaEdit />}
                                        onClick={(ev) => {
                                            ev.preventDefault();
                                            ev.stopPropagation();
                                            editFile(item.id);
                                        }}
                                    />

                                    {item.meta?.deleted_at ? <Button classnames="!bg-orange-500 !hover:text-white" icon={<MdOutlineSettingsBackupRestore />}
                                        onClick={(ev) => {
                                            ev.preventDefault();
                                            ev.stopPropagation();
                                            restoreFile(item.id);
                                        }}
                                    /> :
                                        <Button classnames="!bg-theme-peach-100 !hover:text-white" icon={<FaBoxArchive />}
                                            onClick={(ev) => {
                                                ev.preventDefault();
                                                ev.stopPropagation();
                                                archiveFile(item.id);
                                            }}
                                        />}
                                    <Button classnames="!bg-red-500 !hover:text-white" icon={<RiDeleteBin5Fill />}
                                        onClick={(ev) => {
                                            ev.preventDefault();
                                            ev.stopPropagation();
                                            deleteFile(item.id);
                                        }}
                                    />
                                </div>

                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
};

export default DataListView;
