import React, {useContext, useState} from 'react'
import PageHeading from "../../../components/pageHeading/PageHeading";
import {UserContext} from '../../../../../context/user.context';
import {updateUserProfileData, uploadProfileImage} from '../../../../../service/service.user';
import toast from '../../../../../utils/toast'
import ProfileSection from './components/ProfileSection';

const Profile = (props) => {
    const {user, updateUserProfile, profileUpdateRequest} = useContext(UserContext);
    const [isLoading, setIsLoading] = useState(false);
    const initialValues = {
        snapchat: user.snapchat_link,
        tiktok: user.tiktok_link,
        instagram: user.instagram_link,
        twitter: user.twitter_link,
        facebook: user.facebook_link,
        personal_website: user.personal_website,
        location: user.location,
        bio: user.bio,
        username: user.username,
        email: user.email,
        name: user.name,
    };
    const [profilePicLoading, setProfilePicLoading] = useState(false);
    const [formData, setFormData] = useState({...initialValues})

    const handleChange = (name, value) => {
        setFormData({...formData, [name]: value})
    }

    const handleImageChange = (file) => {
        if (file && file['type'].split("/")[0] !== 'image') {
            toast.error("Unsupported file format; it must be an image");
            return
        }
        setProfilePicLoading(true);
        const formData = new FormData();
        formData.append('image', file);
        uploadProfileImage(formData)
            .then((res) => {
                updateUserProfile({...user, profileImage: res.data?.path});
            })
            .catch((err) => console.log(err))
            .finally(() => setProfilePicLoading(false));

    }

    const onSubmit = async () => {
        const payload = formData;
        try {
            setIsLoading(true)
            await updateUserProfileData(user.id, payload)
                .then((res) => {
                    profileUpdateRequest(res.data.user);
                    setIsLoading(false)
                    toast.success('User details were updated');
                })
                .catch((err) => {
                    setIsLoading(false)
                    console.log(err)
                });
        } catch (error) {
            setIsLoading(false)
            toast.error(error.message);
        }
    }

    return (
        <div>
            <PageHeading
                heading={"Profile"}
                profile
            />
            <div className="flex flex-col gap-4 h-[calc(100vh-70px)] overflow-y-auto font-inter font-medium">
                <div className="col-span-1">
                    <ProfileSection
                        profilePicLoading={profilePicLoading}
                        user={user}
                        formData={formData}
                        onSubmit={onSubmit}
                        isLoading={isLoading}
                        handleChange={handleChange}
                        handleImageChange={handleImageChange}
                    />
                </div>
            </div>
        </div>
    )
}

export default Profile;
