import {Fragment, useContext} from "react";
import {Menu, Transition} from "@headlessui/react";
import {Link, useNavigate} from "react-router-dom";
import { UserContext } from "../../../../context/user.context";

const ProfileDropdown = ({user = null, defaultImage}) => {
    const { logout } = useContext(UserContext); // Access the logout function
    const navigate = useNavigate(); // For redirecting after logout

    const handleLogout = () => {
        logout(); // Clear user state and token
        // navigate("/auth/login"); // Redirect to login page
    };

    return (
        <Menu as="div" className="relative inline-block text-left">
            <div>
                <Menu.Button
                    className={`
                        flex justify-center items-center bg-transparent rounded-md text-sm font-medium text-white 
                        focus:outline-none focus-visible:ring-2 focus-visible:ring-white/75
                        `}>
                    <div className={"flex items-center gap-2"}>
                        <div className={"flex flex-col"}>
                            <span className={"text-[14px] font-medium"}>{user.name || "..."}</span>
                            <span className={"text-[12px]"}>@{user.username}</span>
                        </div>
                        <div className={"w-10 h-10 rounded-full bg-gray-300"}>
                            <img className="w-10 h-10 object-cover rounded-full"
                                 onError={(e) => {
                                     e.target.src = defaultImage;
                                 }}
                                 src={user.profileImage || defaultImage}
                                 alt={"user-image"}/>
                        </div>
                    </div>
                </Menu.Button>
            </div>
            <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
            >
                <Menu.Items
                    className="absolute top-14 right-0 w-52 origin-top-right divide-y divide-gray-100 rounded-sm
                         bg-theme-3 shadow-lg ring-1 ring-black/5 focus:outline-none text-sm font-medium z-[500]">
                    <div className="flex flex-col p-2 font-inter">
                        <Item
                            name={"Upgrade Plan"}
                            link={"/cloud/upgrade"}
                        />
                        <Item
                            name={"Go To Creatorstock"}
                            link={"https://creatorstock.io/"}
                        />
                        <Item
                            name={"Profile"}
                            link={"/settings/profile"}
                        />
                        <Item
                            name={"Change Password"}
                            link={"/settings/change-password"}
                        />
                        <Item
                            name={"Logout"}
                            onClick={handleLogout}
                        />
                    </div>
                </Menu.Items>
            </Transition>
        </Menu>
    )
}

const Item = ({name, link,onClick}) => {
    return (
        <Link
            to={link}
            onClick={onClick}
            className={"h-10 flex items-center px-2 hover:bg-border"}
        >
            {name}
        </Link>
    )
}
export default ProfileDropdown;
