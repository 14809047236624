import React from 'react'

const NotFound = ({ content, subContent = "" }) => {
    return (
        <div className="h-[300px] flex flex-col justify-center items-center text-white text-[14px]">
            {content}
            {subContent ? <p className='mt-1 text-[13px]'>{subContent} </p> : null}
        </div>
    )
}

export default NotFound
