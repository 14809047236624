import React from 'react'
import {Dialog, Transition} from '@headlessui/react'
import {Fragment, useEffect, useState} from 'react'
import {IoClose} from 'react-icons/io5';
import DefaultInput from '../inputs/DefaultInput';
import toast from '../../../../utils/toast'
import {getUserBalance, requestPayout} from '../../../../service/service.user';
import {CURRENCY_SYMBOL} from '../../../../constants/constants';
import SwitchInput from '../inputs/SwitchInput';

const WithdrawlModal = ({isOpen, closeModal}) => {
    const [loading, setLoading] = useState(false);
    const [saving, setSaving] = useState(false);
    const [mainError, setMainError] = useState('');
    const [currentBalance, setCurrentBalance] = useState(0);
    const [details, setDetails] = useState({
        amount: '',
        all: false,
    });
    const [detailsError, setDetailsError] = useState({});

    const loadBalanceDetails = async () => {
        setLoading(true);
        await getUserBalance().then((res) => {
            setCurrentBalance(res.data.walletBalance);
        })
            .finally(() => setLoading(false));
    }

    useEffect(() => {
        loadBalanceDetails();
    }, []);

    const handleChange = (name, value) => {
        setDetails({...details, [name]: value})
    }

    const submitDetails = () => {
        setSaving(true);
        requestPayout(details)
            .then((res) => {
                toast.success(res.data.message);
                closeModal()
                setDetails({
                    amount: '',
                    all: false,
                })
            })
            .catch((err) => setMainError(err.response.data.message))
            .finally(() => setSaving(false));
    }

    const getError = (key) => {
        if (Object.keys(detailsError).length === 0) {
            return '';
        }
        return detailsError.hasOwnProperty(key) ? detailsError[key][0] : '';
    }

    const amountChange = (changeAmount) => {
        setDetails({...details, amount: changeAmount});
        if (changeAmount < 10) {
            setDetailsError({...detailsError, amount: [`Cannot withdraw less than ${CURRENCY_SYMBOL}10`]});
            return;
        }
        if (changeAmount > currentBalance) {
            setDetailsError({...detailsError, amount: ['Withdrawl amount cannot be more than available balance!']});
            return;
        }
        setDetailsError({...detailsError, amount: []});
    }

    const calculatedWithdrawlBalance = () => {
        if (details.all) {
            return Math.round(currentBalance - 0.99).toFixed(2);
        }
        return (details.amount - 0.99).toFixed(2);
    }

    const canWithdraw = () => {
        if (details.all) {
            return !currentBalance >= 10;
        }
        return !details.amount >= 10;
    }
    return (
        <Transition appear show={isOpen} as={Fragment}>
            <Dialog as="div" className="relative z-10" onClose={closeModal} open={isOpen}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-black/80"/>
                </Transition.Child>

                <div className="fixed inset-0 overflow-y-auto">
                    <div className="flex min-h-full items-center justify-center p-4 text-center">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 scale-95"
                            enterTo="opacity-100 scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 scale-100"
                            leaveTo="opacity-0 scale-95"
                        >
                            <Dialog.Panel
                                className="w-full max-w-md transform overflow-hidden rounded-2xl bg-theme-1 p-6 text-left align-middle shadow-xl transition-all">
                                <Dialog.Title
                                    as="h3"
                                    className="text-lg font-medium leading-6 text-white pb-4"
                                >
                                    <div className='flex justify-between items-center'>
                                        <span>Withdrawl Request</span>
                                        <IoClose className='cursor-pointer text-[20px]' onClick={closeModal}/>
                                    </div>
                                </Dialog.Title>

                                <div className="mt-2 flex flex-col gap-2 font-inter">
                                    <span className="text-white">
                                        Available: <b className={"font-medium"}>{CURRENCY_SYMBOL}{currentBalance}</b>
                                    </span>

                                    {!details.all ?
                                        <DefaultInput
                                            className="text-white"
                                            bg={"bg-theme-3"}
                                            value={details.amount}
                                            name="amount"
                                            onChange={handleChange}
                                            placeholder={"Withdraw Amount"}
                                        />
                                        :
                                        null
                                    }

                                    <div className='mt-2'>
                                        <SwitchInput
                                            name="all"
                                            onChange={handleChange}
                                            checked={details.all}
                                            label={"Withdraw All"}/>
                                    </div>
                                    {(details.amount || details.all)
                                        ?
                                        <table className="table text-white">
                                            <tbody>
                                            <tr>
                                                <th className='pl-2 py-2 font-medium' scope="row">Service Fee</th>
                                                <td className='!text-red-600 pl-2 py-2'>{CURRENCY_SYMBOL}-0.99</td>
                                            </tr>
                                            <tr className="table-active">
                                                <th className='pl-2 bg-neutral py-3 font-medium' scope="row">Payout Total</th>
                                                <td className='pl-2 bg-neutral py-3'>
                                                    {CURRENCY_SYMBOL}{calculatedWithdrawlBalance()}
                                                </td>
                                            </tr>
                                            </tbody>
                                        </table>
                                        :
                                        null
                                    }
                                </div>

                                <div className="mt-4 flex justify-end gap-2">
                                    <Button
                                        onClick={() => submitDetails()}
                                        disabled={saving}
                                        name={saving ? 'Processing...' : 'Request Payout'}
                                    />
                                    <Button
                                        onClick={closeModal}
                                        name={"Cancel"}
                                    />
                                </div>
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition>
    )
}


const Button = ({onClick, disabled, name}) => {
    return (
        <button
            type="button"
            disabled={disabled}
            className={`inline-flex justify-center rounded-md px-4 py-2 text-sm font-medium focus:outline-none 
            focus-visible:ring-2 focus-visible:ring-transparent focus-visible:ring-offset-2 
            ${name === "Cancel" ? " bg-white hover:bg-white/90 text-theme-1" : " bg-theme-peach-200 hover:bg-theme-peach-100 text-white"}`}
            onClick={onClick}
        >
            {name}
        </button>
    )
}

export default WithdrawlModal
