import { Fragment, useEffect, useState } from 'react'
import { Combobox, Transition } from '@headlessui/react'
import { IoCheckmark, IoChevronDown } from 'react-icons/io5'

export default function MultiSelectSearch({ name, onChange, options, defaultSelection = [] }) {
  const [selectedCategories, setSelectedCategories] = useState([])
  const [categoryId, setCategoryId] = useState([]);
  const [query, setQuery] = useState('')

  useEffect(() => {
    if (defaultSelection.length) {
      setSelectedCategories(defaultSelection.map((category) => ({ name: category.name + category.emoji, id: category.id })))
      setCategoryId(defaultSelection.map(option => option.id));
    }
  }, [defaultSelection]);

  const filteredCategory =
    query === ''
      ? options
      : options.filter((category) =>
        category.name
          .toLowerCase()
          .replace(/\s+/g, '')
          .includes(query.toLowerCase().replace(/\s+/g, ''))
      )

  const selectOption = (category) => {
    setSelectedCategories([...category])
    setCategoryId([...category.map(option => option.id)])
    onChange(name, [...category.map(option => option.id)])
  }

  const removeOption = (id) => {
    const selectedCategory = selectedCategories.filter((item) => item.id !== id)
    const selectedIds = categoryId.filter((item) => item !== id)
    setSelectedCategories(selectedCategory)
    setCategoryId(selectedIds)
    onChange(name, selectedIds)
  }

  return (
    <div className="">
      <div className="flex flex-wrap mb-5">
        {selectedCategories.map((category) => (
          <div
            key={category.id}
            className="flex items-center justify-between px-2 py-1 mr-2 mt-2 bg-white text-black rounded-full text-xs"
          >
            <span>{category.name}</span>
            <button
              type="button"
              onClick={() => removeOption(category.id)}
              className="ml-2 text-black"
            >
              &times;
            </button>
          </div>
        ))}
      </div>
      <Combobox value={selectedCategories} onChange={selectOption} multiple>
        <div className="relative mt-1">
          <div className="relative w-full cursor-default overflow-hidden rounded-[5px] bg-theme-5 text-left shadow-md focus:outline-none focus-visible:ring-2 focus-visible:ring-white/75 focus-visible:ring-offset-2 focus-visible:ring-offset-teal-300 sm:text-sm">
            <Combobox.Input
              className="w-full h-10 text-[14px] text-white bg-theme-5 rounded-[5px] px-[6px] py-[5px]"
              placeholder="Search categories.."
              onChange={(event) => setQuery(event.target.value)}
            />
            <Combobox.Button className="absolute inset-y-0 right-0 flex items-center pr-2">
              <IoChevronDown
                className="h-5 w-5 text-gray-400"
                aria-hidden="true"
              />
            </Combobox.Button>
          </div>
          <Transition
            as={Fragment}
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
            afterLeave={() => setQuery('')}
          >
            <Combobox.Options className="absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-theme-5 py-1 text-base shadow-lg ring-1 ring-black/5 focus:outline-none sm:text-sm">
              {filteredCategory.length === 0 && query !== '' ? (
                <div className="relative text-white cursor-default select-none px-4 py-2 text-gray-700">
                  Nothing found.
                </div>
              ) : (
                filteredCategory.map((category) => (
                  <Combobox.Option
                    key={category.id}
                    className={({ active }) =>
                      `relative cursor-default select-none py-2 pl-10 pr-4 ${active ? 'bg-white/50 text-white' : 'text-white'
                      }`
                    }
                    value={category}
                    disabled={selectedCategories.some((selected) => selected.id === category.id)}
                    
                  >
                    {({ selected, active }) => (
                      <>
                        <span
                          className={`block truncate ${selected ? 'font-medium' : 'font-normal'
                            }`}
                        >
                          {category.name}
                        </span>
                        {(selected || selectedCategories.some((selected) => selected.id === category.id)) && (
                          <span
                            className={`absolute inset-y-0 left-0 flex items-center pl-3 ${active ? 'text-white' : 'text-white'
                              }`}
                          >
                            <IoCheckmark
                              className="h-5 w-5"
                              aria-hidden="true"
                            />
                          </span>
                        )}
                      </>
                    )}
                  </Combobox.Option>


                ))
              )}
            </Combobox.Options>
          </Transition>
        </div>
      </Combobox>
    </div>
  )
}
