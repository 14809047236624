import React from 'react';
import Thumb from "../../../../assets/images/creator.jpeg"
import Thumb2 from "../../../../assets/images/content2.jpg"
import {IoCloudOutline, IoCloudUploadOutline, IoDocumentAttachOutline, IoHeartOutline, IoImageOutline, IoShareSocialOutline} from "react-icons/io5";
import { IoMdArrowDropright, IoMdArrowDroprightCircle } from 'react-icons/io';

const NoDataCard = () => {
    return (
        <div className="flex flex-col h-[calc(100vh-226px)] justify-center items-center gap-1">
            <IoImageOutline className={"text-[80px] text-white"}/>
            <h1 className={"text-[18px] text-white"}>No Content Uploaded!</h1>
            <span className={"text-[12px] text-gray-500"}>
                Start earning by uploading your library
            </span>
            <button
                className={"mt-2 px-5 h-10 flex items-center text-[14px] text-white bg-theme-peach-100 hover:bg-theme-peach-100 rounded-[5px]"}
            >
                <IoCloudUploadOutline className={"text-[20px] text-white mr-2"}/> Start Uploading
            </button>
        </div>
    );
};

export default NoDataCard;
