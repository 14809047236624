import React, {useRef, useState} from "react";
import {IoFolderOpen} from "react-icons/io5";
import {HiOutlineDotsVertical} from "react-icons/hi";
import FolderRenameModal from "../../components/modals/FolderRenameModal";
import DropdownButton from "../../components/buttons/DropdownButton";

const FolderCard = ({name}) => {
    const [isOpen, setIsOpen] = useState(false)
    const modalRef = useRef();

    function closeModal() {
        setIsOpen(false)
    }

    function openModal() {
        setIsOpen(true)
    }

    return (
        <div className={"bg-white/10 h-12 px-4 flex justify-between items-center rounded-sm text-sm text-white"}>
            <span className={"flex items-center gap-2"}>
                <IoFolderOpen className={"text-xl text-white"}/>
                {name}
            </span>
            <DropdownButton
                title={<HiOutlineDotsVertical/>}
                items={[
                    {name: "Rename", onClick: openModal},
                    {name: "Delete Folder"},
                ]}
            />

            <FolderRenameModal
                isOpen={isOpen}
                modalRef={modalRef}
                closeModal={closeModal}
            />
        </div>
    )
}
export default FolderCard;
