import React, { useContext, useEffect, useState } from "react";
import PageHeading from "../components/pageHeading/PageHeading";
import Search from "../components/search/Search";
import { SUCCESS200, SUCCESS201 } from "../../../constants/constants";
import { UserContext } from "../../../context/user.context";
import MainLoader from "../components/loading/MainLoader";
import CollectionTable from "./components/CollectionTable";
import { deleteCollection, getCollections } from "../../../service/service.collection";
import Button from "../components/buttons/Button";
import CollectionModal from "../components/modals/CollectionModal";
import toast from '../../../utils/toast'
import CommonModal from "../components/modals/CommonModal";
import NotFound from "../components/emptyPage/NotFound";

const Collections = (props) => {
    const [searchText, setSearchText] = useState("");
    const handleSearchText = (value) => {
        setSearchText(value)
    }
    const [view, setView] = useState("grid");
    const [search, setSearch] = useState(false);
    const [collections, setCollections] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const { user } = useContext(UserContext);
    const [collectionData, setCollectionData] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const [deleteModal, setDeleteModal] = useState(false);
    const [deleting, setDeleting] = useState(false);
    const [uuid, setUuid] = useState("");

    const openModal = (data = null) => {
        setCollectionData(data)
        setShowModal(!showModal)
    }

    const closeModal = () => {
        setShowModal(!showModal)
    }

    const closeDeleteModal = () => {
        setDeleteModal(false)
    }

    const openDeleteModal = (id) => {
        setUuid(id);
        setDeleteModal(!deleteModal)
    }

    const handleDeleteModal = () => {
        setDeleting(true);
        deleteCollection(uuid)
            .then((res) => {
                if (res && res.data && (res.data.status === SUCCESS200 || res.data.status === SUCCESS201)) {
                    toast.success("Collection  deleted successfully");
                    closeDeleteModal()
                    loadContents();
                } else {
                    toast.error("Unable to delete Collection ");
                }
            })
            .catch((err) => console.log(err))
            .finally(() => {
                setDeleting(false);
            });
    }

    useEffect(() => {
        loadContents();
    }, []);

    const loadContents = async () => {
        setIsLoading(true);
        await getCollections()
            .then((res) => {
                if (
                    (res.status === SUCCESS200 || res.status === SUCCESS201) &&
                    res.data &&
                    res.data.status === SUCCESS200 &&
                    res.data.collections
                ) {
                    setCollections([...res.data.collections]);
                }
            })
            .catch((err) => console.log(err))
            .finally(() => {
                setIsLoading(false);
            });
    };

    const toggleSearch = () => {
        setSearch(!search);
    };


    return (
        <div>
            <PageHeading
                heading={"Collections"}
                profile
            />
            <div className="flex justify-between m-3">
                <Search
                    folderName={"Search Collections"}
                    searchText={searchText}
                    handleSearchText={handleSearchText}
                />
                <Button name={'New Collection'} onClick={() => openModal()} />
            </div>
            {isLoading ?
                <MainLoader />
                :
                collections && collections.length ?
                    <div className={"p-4"}>
                        <CollectionTable
                            openDeleteModal={openDeleteModal}
                            openEditModal={openModal}
                            collections={collections} />
                    </div> : <NotFound
                        subContent="Click on the 'New Collection' button to add some."
                        content={"No collections found! "}
                    />}

            {showModal
                &&
                <CollectionModal
                    loadContents={loadContents}
                    isOpen={showModal}
                    update={collectionData ? true : false}
                    collectionData={collectionData}
                    closeModal={closeModal} />}
            {deleteModal
                && <CommonModal
                    title={"Delete Collection"}
                    message={"Deleting will permanently remove this Collection from system and cannot be recovered. Are you sure you want to continue?"}
                    isOpen={deleteModal}
                    btnText={deleting ? "Deleting..." : "Delete"}
                    handleConfirm={handleDeleteModal}
                    isActionLoading={deleting}
                    closeModal={closeDeleteModal} />}
        </div>
    )
}
export default Collections;
