import Header from './components/Header'
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import React, { useContext, useEffect, useMemo, useState } from "react";
import "react-confirm-alert/src/react-confirm-alert.css";
import { BiArrowBack } from "react-icons/bi";
import Folders from './components/Folder';
import { FileManagerContext, FileManagerContextProvider } from '../../context/FileManagerContext';
import Button from '../main/components/buttons/Button';
import { IoChevronBack } from 'react-icons/io5';
import PurchaseModal from './components/modal/PurchaseModal';
import File from './components/Files';
import MainLoader from '../main/components/loading/MainLoader';
import DefaultSwitch from '../main/components/switch/defaultSwitch';
import Files from './components/Files';
import ViewContentModal from '../main/components/modals/ViewContentModal';
import { downloadAllFile, downloadFile } from '../../utils/Utils';
import { UserContext } from '../../context/user.context';
import { MAIN_INTERFACE } from '../../constants/constants';
import localStorage from '../../utils/localStorage';

const SubShared = () => {
    const { loadFolders, folderData, loadingData, folderNameData, folderInfo, folderError } = useContext(FileManagerContext);
    const { user } = useContext(UserContext);
    const [view, setView] = useState("grid");
    const [purchase, setPurchase] = useState(false);
    const [folders, setFolders] = folderData;
    const [isLoading, setIsLoading] = loadingData;
    const [folderName, setFolderName] = folderNameData;
    const [uuid, setUuid] = useState("");
    const [viewContent, setViewContent] = useState(false);
    const [enabled, setEnabled] = useState(false)
    const [loading, setLoading] = useState(false)
    const params = useParams();
    const navigate = useNavigate();
    const location = useLocation();

    const closePurchaseModal = () => {
        setPurchase(!purchase)
    }

    const filterDataByType = useMemo(() => {
        const filterData = (type) => folders.filter(item => item.meta?.type == type);
        const files = filterData('file');
        const folderData = filterData('folder');
        return { files, folderData };
    }, [folders]);

    const openPurchaseModal = () => {
        if (!user.isAuthenticated) {
            localStorage.setItem('guest_path', location.pathname);
            window.location.href = '/auth/login';
        } else {
            setPurchase(!purchase)
        }
    }

    const openViewContent = (id) => {
        setUuid(id)
        setViewContent(!viewContent)
    }
    const callback = () => {
        setLoading(false)
    }

    const handleDownloadAll = (id) => {
        if (!user.isAuthenticated) {
            localStorage.setItem('guest_path', location.pathname);
            // window.location.href = MAIN_INTERFACE + '/auth/login?redirect=' + window.location.host;
            window.location.href = '/auth/login';
        } else {
            setLoading(true)
            downloadAllFile(id, 'share', callback)
        }
    }

    const closeViewContent = () => {
        setViewContent(!viewContent)
    }

    useEffect(() => {
        if (params.subid && params.id) {
            loadFolders(params.id, true, params.subid);
        } else if (params.id) loadFolders(params.id, true, null);
    }, [params.id, params.subid]);

    const redirectUser = () => {
        localStorage.setItem('guest_path', location.pathname);
        // window.location.href = MAIN_INTERFACE + '/auth/login?redirect=' + window.location.host;
        window.location.href = '/auth/login';
    }
    return (
        <div className=''>
            <Header
                count={!folderError && folderData && folderData.length > 0 ? folderData.length : 0}
                showCart={!folderError && folderInfo && folderInfo.type === 1 && folderInfo.ownership == "shared_view"}
                onPurchase={openPurchaseModal}
                loading={loading}
                handleDownloadAll={() => handleDownloadAll(params.id)}
            />
            <div className="bg-theme-2">
                <div style={{ height: "calc(100vh - 76px)" }} className="overflow-y-auto px-[40px]">
                    {!isLoading && <div className='text-white mt-5 px-4'>
                        {folderName ? (
                            <div className={"flex justify-between items-center"}>
                                <div className='flex justify-start items-center gap-2 cursor-pointer px-1'>
                                    {params?.subid ? <Button onClick={() => navigate(-1)}
                                        icon={<IoChevronBack className='text-[18px]' />} /> : null}
                                    {folderName}
                                </div>
                            </div>
                        ) : (!folderError && <div className={"flex justify-between items-center"}>
                            <span className={"text-white font-medium"}>Fetching...</span>
                        </div>
                        )
                        }
                    </div>}

                    <div className={"p-4"}>
                        <div>
                            <div className={"flex flex-col gap-2 mb-2"}>
                                <div className="">
                                    {!isLoading && folderError ? (
                                        <div className="flex justify-center items-center text-white h-[70vh]">
                                            <h4>{folderError}</h4>
                                        </div>
                                    ) : isLoading ? (
                                        <MainLoader />
                                    ) : (
                                        folders && folders.length ? (
                                            <div>
                                                <div className={"flex flex-col gap-2 mb-2"}>
                                                    <div className="grid grid-cols-2 lg:grid-cols-4 gap-6">
                                                        {filterDataByType.folderData.map((item, index) => {
                                                            return (
                                                                <div key={index}>
                                                                    <Folders
                                                                        key={item.id}
                                                                        item={item}
                                                                    />
                                                                </div>
                                                            );
                                                        })}
                                                    </div>
                                                </div>
                                                {filterDataByType?.files && filterDataByType.files?.length ?
                                                    <div className='flex flex-col gap-2'>
                                                        <div className={"flex justify-between items-center"}>
                                                            <span className={"text-white font-medium"}>Files</span>
                                                            <DefaultSwitch enabled={enabled} setEnabled={setEnabled} />
                                                        </div>
                                                        <Files
                                                            grid={4}
                                                            openViewContent={!user.isAuthenticated ? redirectUser : openViewContent}
                                                            downloadFile={!user.isAuthenticated ? redirectUser : downloadFile}
                                                            data={filterDataByType?.files}
                                                            enabled={enabled} />
                                                    </div> : null}
                                            </div>
                                        ) : (
                                            <div className='flex justify-center items-center text-white mt-4'>No content found</div>
                                        )
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                    {purchase && <PurchaseModal id={params.id} folder={folderInfo} isOpen={purchase} closeModal={closePurchaseModal} />}
                    {viewContent && <ViewContentModal
                        file={true}
                        id={uuid}
                        isOpen={viewContent}
                        closeModal={closeViewContent}
                    />}
                </div>
            </div>
        </div >
    )
}

export default function Shared(props) {
    return (
        <FileManagerContextProvider>
            <SubShared {...props} />
        </FileManagerContextProvider>
    );
}
