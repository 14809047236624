import React from 'react'
import PageHeading from "../../../components/pageHeading/PageHeading";
import Card from './components/card';

const Watermark = (props) => {

    return (
        <div>
            <PageHeading
                heading={"Watermark"}
                profile
            />
            <div className="flex flex-col gap-4 h-[calc(100vh-70px)] overflow-y-auto font-inter font-medium">
                <Card/>
            </div>
        </div>
    )
}

export default Watermark;
