import React, { useContext, useEffect, useState } from "react";
import PageHeading from "../components/pageHeading/PageHeading";
import PlanCard from "./components/planCard";
import { activateAddonPlan, cancelAddonPlan, cancelSubscriptionPlan, createPackagePurchaseSession, getPackages, keepAddonPlan, keepSubscriptionPlan } from "../../../service/service.user";
import AddonPlanCard from "./components/addonPlanCard";
import CommonModal from "../components/modals/CommonModal";
import toast from "../../../utils/toast";
import { UploaderContext } from "../../../context/uploader.context";

const Upgrade = ({ }) => {
    const {fetchStorage} = useContext(UploaderContext);
    const [loading, setLoading] = useState();
    const [packages, setPackages] = useState([]);
    const [choosenPlan, setChoosenPlan] = useState('');
    const [choosenAddonPlan, setChoosenAddonPlan] = useState('');
    const [subConfirmButton, setSubConfirmButton] = useState('Subscribe Now');
    const [subConfirmDescription, setSubConfirmDescription] = useState("");
    const [subConfirmType, setSubConfirmType] = useState('create');
    const [subConfirmModal, setSubConfirmModal] = useState(null);
    const [addonConfirmButton, setAddonConfirmButton] = useState('Activate Now');
    const [addonConfirmDescription, setAddonConfirmDescription] = useState("");
    const [addonConfirmType, setAddonConfirmType] = useState('create');
    const [addonConfirmModal, setAddonConfirmModal] = useState(null);

    const getPackagesData = () =>{
        setLoading(true); 
        getPackages()
        .then((res) => {
            setPackages(res.data.packages);
        })
        .finally(() => setLoading(false));
    }

    const createPurchaseSession = (packageId) => {
        setChoosenPlan(packageId);
        createPackagePurchaseSession({package_id: packageId})
        .then((res) => {
            if(res.data.uri){
                window.location.href=res.data.uri
            }
            setTimeout(() => {
                toast.success(res.data.message);
                getPackagesData();
                fetchStorage();
            }, 1000);
        }).finally(() => {
            setChoosenPlan('');
        });
    }

    const cancelPlan = (packageId) => {
        cancelSubscriptionPlan({packageId})
        .then(() => getPackagesData())
        .catch((err) => console.log(err));
    }

    const keepPlan = (packageId) => {
        keepSubscriptionPlan({packageId})
        .then(() => getPackagesData())
        .catch((err) => console.log(err));
    }

    const cancelAddonSubPlan = (packageId) => {
        cancelAddonPlan({packageId})
        .then(() => getPackagesData())
        .catch((err) => console.log(err));
    }

    const keepAddonSubPlan = (packageId) => {
        keepAddonPlan({packageId})
        .then(() => getPackagesData())
        .catch((err) => console.log(err));
    }

    useEffect(() => {
        getPackagesData();
    },[]);


    const createAddonPlan = (package_id) => {
        activateAddonPlan({package_id})
        .then(() => getPackagesData())
        .catch((err) => console.log(err));
    }

    const handleSubConfirm = () => {
        if(subConfirmType === 'cancel'){
            cancelPlan(subConfirmModal);
        }
        if(subConfirmType === 'create'){
            createPurchaseSession(subConfirmModal)
        }
        if(subConfirmType === 'keep'){
            keepPlan(subConfirmModal);
        }
        closeConfirmModal();
    }


    const planAction = (type,package_id) => {
        setSubConfirmModal(package_id);
        setSubConfirmType(type);
        if(type === 'cancel'){
            setSubConfirmButton('Cancel Subscription');
            setSubConfirmDescription("You are about to cancel your current subscription. Please note that subscription will continue until the end of the period subscribed for!")
        }
        if(type === 'create'){
            setSubConfirmButton('Subscription Now');
            setSubConfirmDescription("You are about to subscribe to an Paid plan, You will be taken to checkout page for confirming your payment details!");
        }
        if(type === 'keep'){
            setSubConfirmButton('Keep It!');
            setSubConfirmDescription("You reversing your decision of cancelling the plan. ");
        }
    }

    const addonAction = (type, package_id) => {
        setAddonConfirmModal(package_id);
        setAddonConfirmType(type);
        if(type === 'cancel'){
            setAddonConfirmButton('Cancel Addon');
            setAddonConfirmDescription("You are about to cancel your current addon. Please note that addon will continue until the end of the period subscribed for!")
        }
        if(type === 'create'){
            setAddonConfirmButton('Activate Now');
            setAddonConfirmDescription("You are about to subscribe to an Addon, Added price for Addon subscription will be added into your next invoice!");
        }
        if(type === 'keep'){
            setAddonConfirmButton('Keep It!');
            setAddonConfirmDescription("You reversing your decision of cancelling the addon.");
        }
    }

    const handleAddonConfirm = () => {
        if(addonConfirmType === 'create'){
            createAddonPlan(addonConfirmModal);
        }
        if(addonConfirmType === 'cancel'){
            cancelAddonSubPlan(addonConfirmModal);
        }
        if(addonConfirmType === 'keep'){
            keepAddonSubPlan(addonConfirmModal);
        }
        closeConfirmModal();
    }

    const closeConfirmModal = () => {
        setSubConfirmModal(null);
        setSubConfirmType("create");
        setAddonConfirmModal(null);
        setAddonConfirmType("create");
    }

    return (
        <div>
            <PageHeading
                heading={"Upgrade"}
                profile
            />
            <div className={"flex flex-col px-4"}>
                <div className={"flex flex-col items-center justify-center text-white mt-6 mb-[40px]"}>
                    <span className={"text-4xl font-medium"}>Dream Big, keep Hustling! 🚀</span>
                    <span className="text-xl mt-1">Unlock the true value of your content</span>
                </div>
                <div className={"grid lg:grid-cols-3 gap-4"}>
                {packages.filter((item) => item.package_type == 'package').map((item, i) => 
                <PlanCard 
                        choosen={choosenPlan}
                        onClick={(type) => planAction(type,item.id)}
                        theme={item.plan_active ? false:true} 
                        active={item.plan_active}
                        buttontxt={!item.plan_active ?"Choose Plan":'Cancel Plan'}
                        style={"w-full rounded-xl text-white border border-2  p-4 border-white/50"}
                        plan={item}
                />)}
                {packages.filter((item) => item.package_type != 'package').map((item, i) => 
                <AddonPlanCard
                        choosen={choosenPlan}
                        onClick={(type) => addonAction(type,item.id)}
                        theme={item.plan_active ? false:true} 
                        active={item.plan_active}
                        buttontxt={!item.plan_active ?"Activate Addon":'Cancel Addon'}
                        style={"w-full rounded-xl text-white border border-2 p-4 border-white/50"}
                        plan={item}
                />)}
                </div>
            </div>
            <CommonModal
                title={"Attention!"}
                message={subConfirmDescription}
                isOpen={subConfirmModal ? true:false}
                btnText={choosenPlan ? "Processing...":subConfirmButton}
                handleConfirm={handleSubConfirm}
                closeModal={closeConfirmModal}/>
            <CommonModal
                title={"Attention!"}
                message={addonConfirmDescription}
                isOpen={addonConfirmModal ? true:false}
                btnText={choosenAddonPlan ? "Processing...":addonConfirmButton}
                handleConfirm={handleAddonConfirm}
                closeModal={closeConfirmModal}/>
        </div>
        
    )
}
export default Upgrade;