import { createContext, useEffect, useState } from 'react';
import localStorage from '../utils/localStorage';
import { MAIN_INTERFACE } from '../constants/constants';
import { dashboardData } from '../service/service.user';

export const UserContext = createContext({
    user: {
        userData: {},
        isAuthenticated: false,
    },
    logout: () => null,
    login: () => null,
    updateUserProfile: () => null,
    profileUpdateRequest: () => null,
    activeTab: "Dashboard",
    toggleDropDown: () => null,
    getDashboardData: () => null,
    dashboardDetail: null,
    dashboardLoading: true
});

const INITIAL_STATE = {
    userData: {},
    token: null,
    isAuthenticated: false,
};

const initialState = () => {
    const token = localStorage.getItem('token');
    const user = localStorage.getItem('user');

    if (user && token) {
        const userData = user
        return {
            ...userData,
            token,
            isAuthenticated: true
        };
    }
    return INITIAL_STATE;
}

export const UserProvider = ({ children }) => {
    const [user, setUser] = useState(initialState);
    const [activeTab, setActiveTab] = useState("Dashboard");
    const [dashboardDetail, setDashboardDetail] = useState(null);
    const [dashboardLoading, setDashboardLoading] = useState(false);

    useEffect(() => {
        if (!user.isAuthenticated && !window.location.pathname.includes('/sso/') 
            && !window.location.pathname.includes('/shared/') 
            && !window.location.pathname.includes('/register') 
            && !window.location.pathname.includes('/login') ) {
            window.location.href = MAIN_INTERFACE + '/auth/login?redirect=' + window.location.host;
        }
    }, [user]);

    const toggleDropDown = (name) => {
        if (activeTab == name) {
            setActiveTab("Dashboard")
        } else {
            setActiveTab(name)
        }
    };

    const login = (data) => {
        localStorage.setItem("user", data.user);
        localStorage.setItem("token", data.access_token);
        setUser({
            ...data.user,
            token: data.access_token,
            isAuthenticated: true,
        });
    };

    const updateUserProfile = (userData) => {
        setUser({
            ...userData,
        });
        localStorage.setItem("user", userData)
    };

    const profileUpdateRequest = (data) => {
        const newData = { ...data };
        newData.token = user.access_token;
        newData.isAuthenticated = true;
        setUser({ ...newData });
        localStorage.setItem("user", newData);
    };

    const logout = () => {
        setUser({ ...INITIAL_STATE });
    };


    const getDashboardData = () => {
        setDashboardLoading(true)
        dashboardData().then((res) => {
            if (res && res?.data) {
                setDashboardDetail(res.data.data)
            } else {
                setDashboardDetail(null)
            }
            setDashboardLoading(false)
        }).catch((err) => {
            console.log("err", err)
            setDashboardLoading(false)

        })
    }


    return <UserContext.Provider value={{ user, logout, activeTab, toggleDropDown, login, updateUserProfile, profileUpdateRequest, getDashboardData, dashboardDetail, dashboardLoading }}>{children}</UserContext.Provider>;
};