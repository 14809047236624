import React, { useContext, useRef, useState, useEffect, useMemo } from 'react'
import PageHeading from "../components/pageHeading/PageHeading";
import Files from "./components/files";
import ShareModal from "../components/modals/ShareModal";
import Folders from "./components/folders";
import DefaultSwitch from "../components/switch/defaultSwitch";
import Search from '../components/search/Search';
import Button from '../components/buttons/Button';
import AddFolderModal from '../components/modals/AddFolderModal';
import { FileManagerContext, FileManagerContextProvider } from '../../../context/FileManagerContext';
import { UserContext } from '../../../context/user.context';
import { archiveContent, deleteContent, restoreContent } from '../../../service/service.content';
import { SUCCESS200, SUCCESS201 } from '../../../constants/constants';
import toast from '../../../utils/toast'
import AnimationIcons from '../components/animationIcon/AnimationIcon';
import UploadModal from '../components/modals/UploadModal';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { IoChevronBack } from 'react-icons/io5';
import { UploaderContext } from '../../../context/uploader.context';
import EditFolderModal from '../components/modals/EditFolderModal';
import CommonModal from '../components/modals/CommonModal';
import { deleteFolderData } from '../../../service/service.folder';
import { downloadAllFile, downloadFile } from '../../../utils/Utils';
import ViewContentModal from '../components/modals/ViewContentModal';
import EmptyPage from '../components/emptyPage/EmptyPage';
import MainLoader from '../components/loading/MainLoader'
import NotFound from '../components/emptyPage/NotFound';
import SaleDetailModal from '../components/modals/SaleDetailModal';

const MainDrive = () => {
    const { id } = useParams();
    const [enabled, setEnabled] = useState(false)
    const [searchText, setSearchText] = useState("");
    const [showAddFolder, setShowAddFolder] = useState(false);
    const { loadFolders, folderData, loadingData, folderNameData, folderIdData } = useContext(FileManagerContext);
    const { addFile, filesUploading } = useContext(UploaderContext);
    const [view, setView] = useState("grid");
    const [search, setSearch] = useState(false);
    const [openUpload, setOpenUpload] = useState(false)
    const [folders, setFolders] = folderData;
    const [isLoading, setIsLoading] = loadingData;
    const [folderName, setFolderName] = folderNameData;
    const { user, logout } = useContext(UserContext);
    const [folderId, setFolderId] = folderIdData;
    const [editModal, setEditModal] = useState(false);
    const [selectedFolder, setSelectedFolder] = useState(null);
    const [deleteModal, setDeleteModal] = useState(false);
    const [shareModal, setShareModal] = useState(false);
    const [archiveModal, setArchiveModal] = useState(false);
    const [deleteFileModal, setDeleteFileModal] = useState(false);
    const [uuid, setUuid] = useState("");
    const [viewContent, setViewContent] = useState(false);
    const [viewSaleDetail, setViewSaleDetail] = useState(false);
    const navigate = useNavigate();
    const location = useLocation();
    let timeoutId = useRef(null);
    const delay = 1000;

    const filterDataByType = useMemo(() => {
        const filterData = (type) => folders.filter(item => item.meta?.type == type);
        const files = filterData('file');
        const folderData = filterData('folder');
        return { files, folderData };
    }, [folders]);

    const refresh = useMemo(() => {
        return () => {
            if (!Object.values(filesUploading).length) return;
            const filesArray = Object.values(filesUploading);
            const totalFiles = filesArray?.length;
            if (!openUpload && totalFiles > 0 && filesArray.every(file => file?.preview)) {
                setTimeout(() => {
                    loadFreshContent()
                }, 1000);
            }
        };
    }, [filesUploading]);

    useEffect(() => {
        refresh();
    }, [filesUploading])

    const openEditModal = (data) => {
        setSelectedFolder(data?.folder)
        setEditModal(!editModal)
    }

    const openSaleDetailModal = (data) => {
        setSelectedFolder(data?.folder)
        setViewSaleDetail(true)
    }

    const closeEditModal = () => {
        setEditModal(!editModal)
    }

    const openShareModal = (item) => {
        console.log("------", item)
        setSelectedFolder(item.folder)
        setShareModal(!shareModal)
    }

    const closeShareModal = () => {
        setShareModal(!shareModal)
    }

    const openDeleteModal = (item) => {
        setSelectedFolder(item)
        setDeleteModal(!deleteModal)
    }

    const closeDeleteModal = () => {
        setDeleteModal(!deleteModal)
    }

    const handleDeleteModal = (file = selectedFolder) => {
        deleteFolderData(file.id)
            .then((res) => {
                if (res && res.data && (res.data.status === SUCCESS200 || res.data.status === SUCCESS201)) {
                    toast.success(`Folder (${file.meta.name}) is deleted successfully!`);
                    loadFolders(id);
                    closeDeleteModal()
                } else {
                    toast.error("Unable to delete folder!!");
                }
            })
            .catch((err) => {
                toast.error("Unable to delete folder!!");
            })
    }

    const openUploadMOdal = () => {
        setOpenUpload(!openUpload)
    }

    const handleFileUpload = (files) => {
        for (let index = 0; index < files.length; index++) {
            addFile(files[index], folderId ? folderId : null);
        }
        closeUploadMOdal()
    };

    const closeUploadMOdal = () => {
        setOpenUpload(!openUpload)
    }

    const openViewContent = (id) => {
        setUuid(id)
        setViewContent(!viewContent)
    }

    const closeViewContent = () => {
        setViewContent(!viewContent)
    }

    const closeViewSaleDetail = () => {
        setViewSaleDetail(!viewSaleDetail)
    }

    useEffect(() => {
        if (!user.isAuthenticated) {
            logout();
            return;
        }

        if (searchText) return
        loadFolders(id);
    }, [id, window.location.pathname]);

    const loadFreshContent = () => {
        loadFolders(id);
    };

    const toggleSearch = () => {
        setSearch(!search);
    };

    const openAddFolder = () => {
        setShowAddFolder(!showAddFolder)
    }

    const closeAddFolder = () => {
        setShowAddFolder(!showAddFolder)
    }

    const modalRef = useRef();

    const debounce = (func, delay) => {
        return (...args) => {
            clearTimeout(timeoutId.current);
            timeoutId.current = setTimeout(() => {
                func(...args);
            }, delay);
        };
    };

    const debouncedGetContent = debounce((value) => {
        loadFolders(null, null, null, { term: value });
    }, delay);

    const updateURL = () => {
        const currentPath = location.pathname;
        const pathSegments = currentPath.split('/');
        pathSegments.pop();
        const updatedPath = pathSegments.join('/');
        navigate(updatedPath, { replace: true });
    };

    const handleSearchText = (value) => {
        if (id) {
            updateURL()
        }
        setSearchText(value)
        setIsLoading(true)
        debouncedGetContent(value)
    }

    const openArchiveModal = (id) => {
        setUuid(id)
        setArchiveModal(!archiveModal)
    }

    const closeArchieveModal = () => {
        setArchiveModal(!archiveModal)
    }

    const archiveFile = () => {
        archiveContent(uuid)
            .then((res) => {
                if (res && res.data && (res.data.status === SUCCESS200 || res.data.status === SUCCESS201)) {
                    toast.success("Content archived successfully");
                    loadFreshContent();
                    closeArchieveModal()
                } else {
                    toast.error("Unable to archive Content");
                }
            })
            .catch((err) => console.log(err));
    };

    const closeDeleteFileModal = () => {
        setDeleteFileModal(false)
    }

    const openDeleteFileModal = (id) => {
        setUuid(id);
        setDeleteFileModal(!deleteModal)
    }

    const deleteFile = () => {
        deleteContent(uuid)
            .then((res) => {
                if (res && res.data && (res.data.status === SUCCESS200 || res.data.status === SUCCESS201)) {
                    toast.success("Content deleted successfully");
                    loadFreshContent();
                    closeDeleteFileModal()
                } else {
                    toast.error("Unable to delete Content");
                }
            })
            .catch((err) => console.log(err));
    };

    const restoreFile = (uuid) => {
        restoreContent(uuid)
            .then((res) => {
                if (res && res.data && (res.data.status === SUCCESS200 || res.data.status === SUCCESS201)) {
                    toast.success("Content restored successfully");
                    loadFreshContent();
                } else {
                    toast.error("Unable to restore Content");
                }
            })
            .catch((err) => console.log(err));
    };

    const downloadFolder = (data) => {
        downloadAllFile(data.id, 'internal', () => null);
    }

    return (
        <div>
            <PageHeading
                heading={"My Drive"}
                profile
            />
            <Search
                placeholder={"Search files, folders"}
                folderName={"Search Files"}
                searchText={searchText}
                handleSearchText={handleSearchText}
            />
            <div className='text-white mt-5 px-4'>
                {folderName ? (
                    <div>
                        {window.history.length > 1 ? (
                            <div className={"flex justify-between items-center"}>
                                <div className='flex justify-start items-center gap-2 cursor-pointer font-bold'>
                                    <Button onClick={() => navigate(-1)}
                                        icon={<IoChevronBack className='text-[18px]' />} />
                                    {folderName}
                                </div>
                            </div>
                        ) : (
                            folderName
                        )}
                    </div>
                ) : (!searchText &&
                    <div className={"flex justify-between items-center"}>
                        <span className={"text-white font-bold"}>Folders</span>
                    </div>
                )
                }
            </div>

            <div className={"flex flex-col p-4"}>
                {isLoading ? (
                    <MainLoader />
                ) : folders && folders.length > 0 ?
                    <div className={"flex flex-col gap-4"}>
                        <div className={"flex flex-col gap-2"}>
                            <div className="grid grid-cols-2 lg:grid-cols-4 gap-4">
                                {filterDataByType.folderData.map((item, index) => {
                                    return (
                                        <div key={index}>
                                            <Folders
                                                downloadFolder={downloadFolder}
                                                openShareModal={openShareModal}
                                                openEditModal={openEditModal}
                                                openSaleDetailModal={openSaleDetailModal}
                                                deleteFile={openDeleteModal}
                                                item={item}
                                                setSearchText={setSearchText}
                                            />
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                        {filterDataByType?.files && filterDataByType.files?.length ?
                            <div className='flex flex-col gap-2'>
                                <div className={"flex justify-between items-center"}>
                                    <span className={"text-white font-medium"}>Files</span>
                                    <DefaultSwitch enabled={enabled} setEnabled={setEnabled} />
                                </div>
                                <Files
                                    archiveFile={openArchiveModal}
                                    openViewContent={openViewContent}
                                    deleteFile={openDeleteFileModal}
                                    restoreFile={restoreFile}
                                    downloadFile={downloadFile}
                                    data={filterDataByType?.files}
                                    enabled={enabled} />
                            </div> : null}
                    </div>
                    : searchText ?
                        <NotFound content={"No Result Found"} />
                        :
                        <EmptyPage content={"Create a new folder or upload your content directly in here."} />
                }
            </div>
            {viewContent &&
                <ViewContentModal
                    file={true}
                    id={uuid}
                    isOpen={viewContent}
                    closeModal={closeViewContent}
                />
            }
            {viewSaleDetail && <SaleDetailModal
                folder={selectedFolder}
                id={uuid}
                isOpen={viewSaleDetail}
                closeModal={closeViewSaleDetail}
            />}
            {editModal &&
                <EditFolderModal
                    loadFreshContent={loadFreshContent}
                    folder={selectedFolder}
                    isOpen={editModal}
                    closeModal={closeEditModal} />
            }
            {deleteModal &&
                <CommonModal
                    title={"Delete Folder"}
                    message={"Deleting will permanently remove this folder from system and cannot be recovered. Are you sure you want to continue?"}
                    isOpen={deleteModal}
                    btnText={"Delete"}
                    handleConfirm={handleDeleteModal}
                    closeModal={closeDeleteModal} />
            }

            {deleteFileModal &&
                <CommonModal
                    title={"Delete File"}
                    message={"Deleting will permanently remove this file from system and cannot be recovered. Are you sure you want to continue?"}
                    isOpen={deleteFileModal}
                    btnText={"Delete"}
                    handleConfirm={deleteFile}
                    closeModal={closeDeleteFileModal} />
            }
            {archiveModal &&
                <CommonModal
                    title={"Archive File"}
                    message={"Are you sure you want to Archive this file?"}
                    isOpen={archiveModal}
                    btnText={"Archive"}
                    handleConfirm={archiveFile}
                    closeModal={closeArchieveModal} />}

            {showAddFolder &&
                <AddFolderModal
                    isPaid={id ? false : true}
                    isOpen={showAddFolder}
                    closeModal={closeAddFolder}
                />
            }
            {openUpload &&
                <UploadModal
                    handleFileUpload={handleFileUpload}
                    isOpen={openUpload}
                    closeModal={closeUploadMOdal}
                />
            }
            {shareModal &&
                <ShareModal
                    folder={selectedFolder}
                    isOpen={shareModal}
                    modalRef={modalRef}
                    closeModal={closeShareModal} />
            }
            <AnimationIcons
                id={id}
                openUploadMOdal={openUploadMOdal}
                openAddFolder={openAddFolder}
            />

        </div>
    )
}

export default function Drive(props) {
    return (
        <FileManagerContextProvider>
            <MainDrive {...props} />
        </FileManagerContextProvider>
    );
}
