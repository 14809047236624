import React, { Fragment, useContext, useEffect, useRef, useState } from 'react';
import MainLoader from '../../../../components/loading/MainLoader.jsx';
import NoThumb from '../../../../../../assets/images/no-thumb.jpg';
import toast from '../../../../../../utils/toast.js';
import { UserContext } from '../../../../../../context/user.context.js';
import { getUserProfile, uploadWatermarkImage } from "../../../../../../service/service.user";
import UploadWatermark from "../../profile/components/UploadWatermark";

const Card = () => {
    const [isLoading, setIsLoading] = useState(false);
    const { user } = useContext(UserContext);
    const [show, setShow] = useState(false);
    const [watermark, setWatermark] = useState("");
    const [fetch, setFetch] = useState(false);

    useEffect(() => {
        const userData = user || JSON.parse(localStorage.getItem("user"));
        if (userData && !userData?.username) return
        setFetch(true)
        getUserProfile(userData.username).then((res) => {
            if (res && res?.data) {
                setWatermark(res.data?.watermarkImage);
                setFetch(false)
            }
        }).catch(() => {
            setFetch(false)
        })
    }, []);

    const uploadFile = (formData) => {
        uploadWatermarkImage(formData)
            .then((res) => {
                if (res && res.data && res.data.path) {
                    setWatermark(res.data.path);
                    setShow(false)
                    setIsLoading(false)
                    toast.success(res.data?.message)
                }
            })
            .catch((err) => {
                setShow(true)
                setIsLoading(false)
                console.log(err)
            })
            .finally(() => setIsLoading(false));
    }

    const handleFileUpload = (files) => {
        const file = files[0];
        if (!file) {
            return;
        }
        if (!['image/png', 'image/jpeg'].includes(file.type)) {
            toast.error('Unsupported file format. Please upload a PNG or JPG file.');
            return;
        }
        setShow(false)
        setIsLoading(true);
        const formData = new FormData();
        formData.append("image", file);
        uploadFile(formData)
    }

    const clearWatermark = () => {
        setWatermark(""); // Clear the watermark
    }

    return (
        <Fragment>
            {watermark ?
                <div className='w-full h-full flex justify-center items-center'>
                    {fetch || isLoading ?
                        <MainLoader height={"h-[150px]"}/>
                        :
                        <div className={"flex flex-col items-center gap-2 text-white"}>
                            <div
                                className={`w-52 h-52 border border-dashed border-border flex justify-center
                                 items-center rounded-md`}>
                                <img
                                    onError={(e) => {
                                        e.target.src = NoThumb;
                                    }}
                                    src={watermark}
                                    className='h-20 w-20 object-contain'
                                    alt={"logo"}
                                />
                            </div>
                            <span className={"underline text-blue cursor-pointer"} onClick={clearWatermark}>
                                Change Watermark
                            </span>
                        </div>
                    }
                </div>
                :
                <UploadWatermark
                    multiple={false} handleFileUpload={handleFileUpload}
                    info={"Files must be in PNG or JPG with max size of 100 MB."}
                />
            }
        </Fragment>
    )
}

export default Card;
