import { Fragment } from "react";
import { Menu, Transition } from "@headlessui/react";
import { BsThreeDotsVertical } from "react-icons/bs";

const DefaultDropdown = ({ data = null, content }) => {
    return (
        <Menu as="div" className="relative inline-block text-left">
            <div>
                <Menu.Button
                    className={`
                        flex justify-center items-center bg-transparent rounded-md text-sm font-medium text-white 
                        focus:outline-none focus-visible:ring-2 focus-visible:ring-white/75
                        `}>
                    <BsThreeDotsVertical />
                </Menu.Button>
            </div>
            <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
            >
                <Menu.Items
                    className="absolute right-0 w-40 origin-top-right divide-y divide-gray-100 rounded-sm
                         bg-theme-3 shadow-lg ring-1 ring-black/5 focus:outline-none text-sm font-medium z-[500]">
                    <div className="px-1 py-1">
                        {content?.map((item, index) => {
                            return (
                                <Menu.Item key={index} className={"text-white z-20"}>
                                    {({ active }) => (
                                        <button
                                            className={`${active ? 'bg-neutral text-white' : 'text-white'
                                            } group flex w-full items-center rounded-sm px-2 text-sm font-inter 
                                            hover:bg-neutral h-8 `}
                                            onClick={(e) => {
                                                e.stopPropagation()
                                                item.onClick(data)
                                            }}
                                        >
                                            {item.label}
                                        </button>
                                    )}
                                </Menu.Item>
                            )
                        })}
                    </div>
                </Menu.Items>
            </Transition>
        </Menu>
    )
}
export default DefaultDropdown;
