import React from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { Fragment, useEffect, useState } from 'react'
import { IoClose } from 'react-icons/io5';
import DefaultInput from '../inputs/DefaultInput';
import toast from '../../../../utils/toast'
import { getUserBalance, requestPayout } from '../../../../service/service.user';
import { CURRENCY_SYMBOL } from '../../../../constants/constants';
import SwitchInput from '../inputs/SwitchInput';
import TextareaInput from '../inputs/TextareaInput';
import { TbCoinPound } from 'react-icons/tb';
import { createCollection, updateCollection } from '../../../../service/service.collection';

const initialValue = {
    name: '',
    description: '',
    price: 0,
};

const CollectionModal = ({ isOpen, closeModal, loadContents, collectionData, update }) => {
    const [loading, setLoading] = useState(false);
    const [saving, setSaving] = useState(false);
    const [mainError, setMainError] = useState('');
    const [currentBalance, setCurrentBalance] = useState(0);
    const [details, setDetails] = useState(initialValue);
    const [detailsError, setDetailsError] = useState({});

    useEffect(() => {
        if (!update) return
        if (collectionData && update) {
            setDetails({
                uuid: collectionData.id,
                name: collectionData.name,
                description: collectionData.description,
                price: collectionData.price,
            })
        }
        return () => {
            setDetails(initialValue)
        }
    }, [update])


    const submitDetails = () => {
        if (!details.name) {
            setDetailsError({ name: "Name filed is requried" })
            return
        }
        setSaving(true);
        if (update) {
            const { uuid, ...data } = details;
            updateCollection(uuid, data).then((res) => {
                toast.success(res.data.message);
                closeModal()
                setDetails(initialValue);
                setSaving(false)
                loadContents()
            })
                .catch((err) => setMainError(err.response.data.message))
                .finally(() => setSaving(false));
        } else {
            createCollection(details)
                .then((res) => {
                    toast.success(res.data.message);
                    closeModal()
                    setDetails(initialValue);
                    setSaving(false)
                    loadContents()
                })
                .catch((err) => setMainError(err.response.data.message))
                .finally(() => setSaving(false));
        }
    }

    const handleCheckValidation = (name, value) => {
        if (name === "name" && value) {
            setDetailsError({})
        }
    }

    const handleChange = (name, value) => {
        setDetails({ ...details, [name]: value })
        handleCheckValidation(name, value)
    }

    return (
        <Transition appear show={isOpen} as={Fragment}>
            <Dialog as="div" className="relative z-10" onClose={closeModal} open={isOpen}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-black/80" />
                </Transition.Child>

                <div className="fixed inset-0 overflow-y-auto">
                    <div className="flex min-h-full items-center justify-center p-4 text-center">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 scale-95"
                            enterTo="opacity-100 scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 scale-100"
                            leaveTo="opacity-0 scale-95"
                        >
                            <Dialog.Panel
                                className="w-full max-w-md transform overflow-hidden rounded-2xl bg-theme-1 p-6 text-left align-middle shadow-xl transition-all">
                                <Dialog.Title
                                    as="h3"
                                    className="text-lg font-medium leading-6 text-white pb-4"
                                >
                                    <div className='flex justify-between items-center'>
                                        <span>{update ? "Udpate Collection" : "Create Collection"}</span>
                                        <IoClose className='cursor-pointer text-[20px]' onClick={closeModal} />
                                    </div>
                                </Dialog.Title>

                                <div className="mt-2 flex flex-col gap-2">
                                    <DefaultInput
                                        className="text-white"
                                        bg={"bg-theme-3"}
                                        value={details.name}
                                        name="name"
                                        onChange={handleChange}
                                        placeholder={"Collection Name"}
                                    />
                                    {detailsError?.name ? <span className='text-red-500 text-[11px] mt-[-5px]'>{detailsError.name}</span> : null}
                                    <TextareaInput
                                        className="text-white"
                                        bg={"bg-theme-3"}
                                        value={details.description}
                                        name="description"
                                        onChange={handleChange}
                                        placeholder={"Description..."}
                                    />
                                    <DefaultInput
                                        className="text-white"
                                        bg={"bg-theme-3"}
                                        icon={<TbCoinPound />}
                                        value={details.price}
                                        name="price"
                                        type='number'
                                        onChange={handleChange}
                                        placeholder={"Price"}
                                    />
                                    <span className={'text-[12px] text-white'}>Add content to collection by navigating to the My content section</span>
                                </div>

                                <div className="mt-4 flex justify-end gap-2">
                                    <Button
                                        onClick={() => submitDetails()}
                                        disabled={saving}
                                        name={saving ? 'Processing...' : (update ? 'Update Collection' : 'Create Collection')}
                                    />
                                    <Button
                                        onClick={closeModal}
                                        name={"Cancel"}
                                    />
                                </div>
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition>
    )
}


const Button = ({ onClick, disabled, name }) => {
    return (
        <button
            type="button"
            disabled={disabled}
            className={`inline-flex justify-center rounded-md px-4 py-2 text-sm font-medium focus:outline-none 
            focus-visible:ring-2 focus-visible:ring-transparent focus-visible:ring-offset-2 
            ${name === "Cancel" ? " bg-white hover:bg-white/90 text-theme-1" : " bg-theme-peach-200 hover:bg-theme-peach-100 text-white"}`}
            onClick={onClick}
        >
            {name}
        </button>
    )
}

export default CollectionModal
