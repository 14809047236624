import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import MainLoader from '../../main/components/loading/MainLoader';
import { useSearchParams } from 'react-router-dom';
import { WHITE_LIST_URLS } from '../../../utils/Utils';

const Authentication = ({ children }) => {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);
    const [searchParams] = useSearchParams();

    const parseRedirect = (token) => {
        if (searchParams.get('redirect').includes('localhost')) {
            return 'http://' + searchParams.get('redirect') + '/sso/' + token.replace(/^"(.*)"$/, '$1');
        }
        return 'https://' + searchParams.get('redirect') + '/sso/' + token.replace(/^"(.*)"$/, '$1');
    }

    useEffect(() => {
        const checkToken = async () => {
            await new Promise(resolve => setTimeout(resolve, 1000));
            const isAuthenticated = localStorage.getItem('token');
            if (isAuthenticated) {
                if (searchParams.get("redirect") && WHITE_LIST_URLS.includes(searchParams.get("redirect"))) {
                    window.location.href = parseRedirect(isAuthenticated);
                    return;
                } else {
                    navigate('/');
                    return;
                }
            }
            setLoading(false);
        };

        checkToken();
    }, [searchParams, navigate]);

    return loading ? 
        <div className={"flex w-full h-screen items-center justify-center"}>
            <MainLoader />
        </div> 
        : 
        <>{children}</>;
};

export default Authentication;
