"use client"
import React from "react";
import Logo from '../../../../assets/images/creatorstock.svg';
import AuthInputFiled from "../../../components/inputs/input.auth";
import { Link } from "react-router-dom";
import AuthButton from "../../../components/buttons/authButton";

const Register = ({ getFieldProps, touched, errors, onSubmit, isLoading }) => {
    return (
        <div className="[h-screen overflow-y-auto w-full relative bg-banner bg-cover h-screen bg-black">
            <div className="flex justify-center items-center h-full">
                <div className="max-w-[500px] px-5 w-full py-10">
                    <form>
                        <div className={"flex flex-col gap-4"}>
                            <img
                                src={Logo}
                                className="w-16 object-cover align-middle m-auto"
                                alt="loading logo"
                            />
                            <h2 className="text-white text-2xl lg:text-3xl font-normal mb-1 text-center">
                                Create an <span className="font-bold">Account</span>
                            </h2>
                            <p className="text-white text-sm font-normal text-center">
                                Download &amp; Upload High-Quality Content
                            </p>
                        </div>
                        <div className="grid grid-cols-2 gap-4 my-3 mt-8">
                            <AuthInputFiled
                                error={touched.username && errors.username}
                                register={getFieldProps("username")} label={"Username"}
                                name={"username"} placeholder={"Username"}
                            />
                            <AuthInputFiled
                                error={touched.name && errors.name}
                                register={getFieldProps("name")}
                                label={"Your Name"} name={"name"}
                                placeholder={"Your Name"}
                            />
                        </div>
                        <div className="my-3">
                            <AuthInputFiled
                                error={touched.email && errors.email}
                                register={getFieldProps("email")}
                                label={"Email"} name={"email"}
                                placeholder={"email@xyz.com"}
                            />
                        </div>
                        <div className="grid grid-cols-2 gap-4 my-3">
                            <AuthInputFiled
                                type="password"
                                error={touched.password && errors.password}
                                register={getFieldProps("password")}
                                label={"Password"}
                                name={"password"} placeholder={"Password"}
                            />
                            <AuthInputFiled
                                type="password" error={touched.password2 && errors.password2}
                                register={getFieldProps("password2")}
                                label={"Confirm Password"}
                                name={"password2"}
                                placeholder={"Confirm Password"}
                            />
                        </div>
                        <div className="block mb-0.5 min-h-[1.5rem] pl-[1.5em] my-2">
                            <input
                                {...getFieldProps("checked")}
                                className="flex-shrink-0 h-[1em] mt-[0.25em] align-top w-[1em] rounded-[0.25em] float-left -ml-[1.5em]"
                                type="checkbox" id="flexCheckDefault"
                            />
                            <label
                                className="text-sm font-normal text-white"
                                htmlFor="flexCheckDefault">
                                By continuing, you agree to the
                                <a target="_blank" href="https://creatorstock.io/terms"> Terms of use </a>
                                and
                                <a target="_blank" href="https://creatorstock.io/privacy-policy"> Privacy Policy</a>, of CreatorStock.
                            </label>
                        </div>
                        <div className="flex justify-center items-center mt-4">
                            <AuthButton
                                disabled={isLoading}
                                name="Sign Up"
                                onClick={onSubmit}
                                isLoading={isLoading}
                            />
                        </div>
                        <div className="flex items-center mt-2 justify-center text-white text-[16px] font-normal gap-[4px] leading-[16px] text-center">
                            Already have an account?
                            <Link className="hover:underline" href="/auth/login">
                                Login
                            </Link>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default Register;
