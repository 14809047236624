import React, { Fragment, useEffect, useRef, useState } from 'react'
import { RiArrowDropDownLine } from "react-icons/ri";
import { RiArrowDropUpLine } from "react-icons/ri";
import Button from '../../components/buttons/Button';
import { RiDeleteBinLine } from "react-icons/ri";
import { HiOutlineTemplate } from 'react-icons/hi';


const SelectedItems = ({ data, handleDeleteSelectedItems, handleAddtoCollection, handleBulkDelete }) => {
    const [show, setShow] = useState(false)
    const accordionRef = useRef(null);

    return (
        <div
            class="group-data flex flex-col gap-2 rounded-lg bg-theme-6 border border-white/50 p-3 text-white w-[100%] lg:w-[50%]"
            tabindex="3"
        >
            <div class="flex cursor-pointer items-center justify-between">
                <span>({data.length}) Selected Items </span>
                <div className='flex gap-2'>
                    <Button onClick={() => handleAddtoCollection()} name={"Add to collection"} />
                    <Button onClick={() => handleBulkDelete()} icon={<RiDeleteBinLine className='text-[18px]' />} />
                    <div
                        class={`transition-all duration-500 group-data-focus:-rotate-${show ? 180 : 0}`}
                        onClick={() => setShow(!show)}>
                        {show ?
                            <RiArrowDropDownLine className='text-[40px]' /> :
                            <RiArrowDropUpLine className='text-[40px]' />}
                    </div>
                </div>
            </div>
            {show ?
                <Fragment>
                    <div className='border-b-[1px] border-white/50 mb-3'></div>
                    <div className='max-h-[250px] overflow-y-auto overflow-x-hidden custom-scrollbar'>
                        {data.map((item) => {
                            return <Items item={item} handleDeleteSelectedItems={handleDeleteSelectedItems} />
                        })}
                    </div>
                </Fragment>
                : null}
        </div>
    )
}

const Items = ({ item, handleDeleteSelectedItems }) => {
    return (
        <div className='flex justify-between items-center px-2 mb-1.5'>
            <div className='flex items-center gap-2'>
                < div className='bg-black/10 rounded-md'>
                    <img width={50} height={50} className='cover bg-red-50 w-[50px] h-[50px] rounded-md' src={item.meta.image} />
                </div>
                <p className=''>
                    {item.meta?.name || "No caption available"}
                </p>
            </div>
            <Button
                onClick={() => handleDeleteSelectedItems(item)}
                icon={<RiDeleteBinLine className='text-[18px]' />} />
        </div>
    )
}

export default SelectedItems