import { Menu } from '@headlessui/react'
import React, { useContext } from "react";
import { FaChevronDown } from "react-icons/fa";
import { NavLink } from "react-router-dom";
import { UserContext } from '../../../../context/user.context';
import localStorage from '../../../../utils/localStorage';
import { MAIN_INTERFACE } from '../../../../constants/constants';

const MenuDropdown = ({ name, icon, subMenu, path, location, active }) => {
    const { activeTab, toggleDropDown } = useContext(UserContext);

    const isActivePath = (pathname) => {
        if (pathname === "/" || !pathname) return
        return location.pathname.startsWith(pathname)
    }

    const handleLogout = () => {
        localStorage.removeAllItems();
        // window.location.href ='https://auth.creatorstock.io/?redirect=https://cloud.creatorstock.io';
        window.location.href = MAIN_INTERFACE + '/auth/login?redirect=' + window.location.host;
    };


    return (
        <Menu>
            <Menu.Button onClick={() => toggleDropDown(name)}
                className={`flex justify-between items-center p-0 text-sm text-white rounded-sm h-10 px-2
                ${active === true && "bg-black"},${location.pathname === path ? 'bg-theme-2' : ''} 
               `}>

                <div className={"flex items-center gap-2"}>
                    <span className={"text-2xl"}>{icon}</span>
                    {name}</div>
                <FaChevronDown />
            </Menu.Button>
            <Menu.Items className={"pl-5"} static>
                {activeTab === name && (
                    subMenu.map((item, index) => {
                        const path = item.path === "logout" ? `javascript:void(0)` : item.path;
                        return (
                            <NavLink
                                to={path}
                                key={index}
                                onClick={item.name === "Logout" ? handleLogout : undefined}
                                className={`${isActivePath(item?.path) ? 'bg-theme-2 hover:bg-theme-2' : 'hover:bg-theme-2'}
                                    flex gap-2 items-center py-2 px-4 font-inter font-medium text-sm text-white h-10 text-left rounded-sm qwwqe34`}
                            >
                                {item.name}
                            </NavLink>
                        )
                    })
                )}
            </Menu.Items>
        </Menu>
    )
}
export default MenuDropdown;
