import React from 'react'
import { FiFolder, FiUpload } from "react-icons/fi";
import { IoWalletOutline } from "react-icons/io5";
import { GrStorage, GrUpgrade } from "react-icons/gr";
import { FaHandHoldingUsd } from "react-icons/fa";

const CreatorCloudCard = ({ dashboardDetail }) => {


    return (
        <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
            <Tab name={"Wallet"} icon={<IoWalletOutline />} colors={"border-yellow-500"} data={`£${parseFloat(dashboardDetail?.wallet).toFixed(2) || '0'}`} />
            <Tab name={"Total uploads"} icon={<FiUpload />} colors={"border-indigo-500"} data={dashboardDetail?.totalUploads || '0'} />
            <Tab name={"Total folders"} icon={<FiFolder />} colors={"border-pink-500"} data={dashboardDetail?.totalFolders || '0'} />
            <Tab name={"Storage used"} icon={<GrStorage />} colors={"border-red-500"} data={dashboardDetail?.storageUsed || '0'} />
            <Tab name={"Your Plan"} icon={<GrUpgrade />} colors={"border-green-500"} data={dashboardDetail?.yourPlan || '0'} />
            <Tab name={"Completed Paid Collaborations"} icon={<FaHandHoldingUsd />} colors={"border-blue-500"} data={dashboardDetail?.completedPaidCollaborations || '0'} />
        </div>
    )
}

const Tab = ({ name, icon, colors, data }) => {
    return (
        <div className={`bg-theme-3 border-l-2 ${colors ? colors : ""}`}>
            <div className='flex flex-col items-start h-full w-full p-5 gap-4'>
                <span className={`text-white text-xl w-10 h-10 rounded-full flex justify-center items-center border ${colors}`}>
                    {icon}
                </span>
                <div>
                    <p className='text-lg text-white font-medium'>{name}</p>
                    {/* <p className='text-xs pt-2 text-white'>{"£300000.99"}</p> */}
                    <p className='text-xs pt-2 text-white'>{data}</p>
                </div>
            </div>
        </div>
    )
}

export default CreatorCloudCard
