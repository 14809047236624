import React, { useContext, useEffect, useState } from 'react'
import PageHeading from "../components/pageHeading/PageHeading";
import WelcomeMessage from "./components/WelcomeMessage";
import { Tab } from '@headlessui/react'
import CreatorCloudCard from './components/CreatorCloudCard';
// import CreatorStockCard from './components/CreatorStockCard';
import { UserContext } from '../../../context/user.context';
import MainLoader from '../components/loading/MainLoader';

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

const Home = (props) => {
    const [selectedCatgory, setSelectedCatgory] = useState("CreatorCloud")
    let [categories] = useState({
        CreatorCloud: [
            {
                id: 1,
                title: 'Does drinking coffee make you smarter?',
                date: '5h ago',
                commentCount: 5,
                shareCount: 2,
            },
            {
                id: 2,
                title: "So you've bought coffee... now what?",
                date: '2h ago',
                commentCount: 3,
                shareCount: 2,
            },
        ],
        // CreatorStock: [
        //     {
        //         id: 1,
        //         title: 'Is tech making coffee better or worse?',
        //         date: 'Jan 7',
        //         commentCount: 29,
        //         shareCount: 16,
        //     },
        //     {
        //         id: 2,
        //         title: 'The most innovative things happening in coffee',
        //         date: 'Mar 19',
        //         commentCount: 24,
        //         shareCount: 12,
        //     },
        // ],
    })


    const { getDashboardData, dashboardDetail, dashboardLoading } = useContext(UserContext);

    useEffect(() => {
        getDashboardData();
    }, []);

    return (
        <div>
            <PageHeading
                heading={"Dashboard"}
                profile
            />

            <div className={"p-4"}>
                <WelcomeMessage />
                <Tab.Group>
                    <Tab.List className="flex space-x-1 rounded-xl bg-black/20 p-1 w-[400px]">
                        {Object.keys(categories).map((category) => (
                            <Tab
                                key={category}
                                onClick={() => setSelectedCatgory(category)}
                                className={({ selected }) =>
                                    classNames(
                                        'w-full rounded-lg py-2.5 text-[14px] text-gray-200 font-medium leading-5',
                                        'ring-0 ring-offset-0  focus:outline-none focus:ring-0',
                                        selected
                                            ? 'bg-theme-3 text-gray-100 shadow'
                                            : 'text-gray-100 hover:bg-theme-2 hover:text-gray-200'
                                    )
                                }
                            >
                                {category}
                            </Tab>
                        ))}
                    </Tab.List>
                    <Tab.Panels className="mt-2">
                        {dashboardLoading ? <MainLoader /> :
                            Object.values(categories).map((posts, idx) => (
                                <Tab.Panel
                                    key={idx}
                                    className={classNames(
                                        'rounded-xl p-3',
                                        'ring-0 ring-offset-0 focus:outline-none focus:ring-0'
                                    )}
                                >
                                    {selectedCatgory === "CreatorCloud" ?
                                        <CreatorCloudCard dashboardDetail={dashboardDetail} /> : ""
                                        // <CreatorStockCard />
                                    }
                                </Tab.Panel>
                            ))
                        }
                    </Tab.Panels>
                </Tab.Group>
            </div>
        </div>
    )
}

export default Home;
