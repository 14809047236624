import React, { useEffect, useState } from 'react'
import { useFormik } from 'formik';
import { forgotPasswordOtpGen, verifyForgotPasswordOtp } from '../../../../service/userService';
import { Link } from 'react-router-dom';
import AuthInputFiled from '../../../components/inputs/input.auth';
import AuthButton from '../../../components/buttons/authButton';
import toast from 'react-hot-toast';
import Logo from '../../../../assets/images/creatorstock.svg';
import * as Yup from "yup";

const forgotPasswordValidation = Yup.object().shape({
    email: Yup.string().email("Invalid Email").required("Email is required"),
  });
const ForgotPassword = ({ handleToggle }) => {
    const [customErrors, setCustomErrors] = useState({});
    const [userId, setUserId] = useState(0);
    const [timer, setTimer] = useState(0);
    const [showVerification, setShowVerification] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [email, setEmail] = useState("");

    useEffect(() => {
        setIsLoading(false);
        setCustomErrors({});
        setShowVerification(false);
        setUserId(0);
        setTimer(0);
        setEmail("");
    }, []);

    const formik = useFormik({
        initialValues: {
            email: "",
        },
        validationSchema: forgotPasswordValidation,
        onSubmit: async (data) => {
            setIsLoading(true);
            setEmail(data.email);
            forgetSubmit(data.email);
        },
    });

    const startTimer = () => {
        const interval = setInterval(() => {
            setTimer(prevTimer => {
                if (prevTimer === 0) {
                    clearInterval(interval);
                    return 0;
                } else {
                    return prevTimer - 1;
                }
            });
        }, 1000);
    };

    const forgetSubmit = async (email) => {
        if (timer !== 0) {
            return;
        }
        await forgotPasswordOtpGen({ email })
            .then((res) => {
                if (
                    res.status === 200 &&
                    res.data &&
                    res.data.status === 200 &&
                    res.data.id
                ) {
                    setUserId(res.data.id);
                    setShowVerification(true);
                    toast.success("Code sent successfully!");
                    setTimer(60);
                    startTimer()
                } else {
                    if (res.data.error) {
                        setCustomErrors(res.data.error);
                        setIsLoading(false)
                        toast.error("Oops!, we couldn't find an account with that email. Please try again.")
                    } else {
                        setIsLoading(false)
                        toast.error("Unable to send the code!");
                    }
                }
            })
            .catch((err) => {
                toast.error("Unable to send the code!");
                console.log(err);
            })
            .finally(setIsLoading(false));
    };

    const submitVerification = async (values) => {
        try {
            setIsLoading(true);
            await verifyForgotPasswordOtp({
                ...values,
                user_id: userId,
            })
                .then(async (res) => {
                    if (res.status === 200 && res.data && res.data.status === 200) {
                        setCustomErrors({});
                        setShowVerification(false);
                        setUserId(0);
                        setTimer(0);
                        setEmail("");
                        toast.success("Password updated successfully!");
                        setIsLoading(false);
                    } else if (res.data && res.data.error) {
                        setCustomErrors(res.data.error);
                        toast.error(res.data.error?.verification_code)
                        setIsLoading(false);
                    }
                })
                .catch((err) => {
                    console.log(err);
                    setIsLoading(false);
                });
        } catch (error) {
            setIsLoading(false);
        }
    };

    const { handleSubmit, touched, errors, getFieldProps } = formik;

    return (
        !showVerification ? (
            <ForgotScreen
                handleSubmit={handleSubmit}
                touched={touched}
                getFieldProps={getFieldProps}
                handleToggle={handleToggle}
                errors={errors}
                isLoading={isLoading}
            />
        ) : (
            // <ResetPassword
            //     timer={timer}
            //     resendOtp={forgetSubmit}
            //     error={customErrors}
            //     email={email}
            //     isLoading={isLoading}
            //     onSubmit={submitVerification}
            // />
            ""
        )
    )
}

const ForgotScreen = ({ handleSubmit, isLoading, touched, getFieldProps, errors, handleToggle }) => {
    return (
        <div className="[h-screen overflow-y-auto w-full relative bg-banner bg-cover h-screen bg-black">
            <div className="flex justify-center items-center h-full">
                <div className="max-w-[500px] px-5 w-full py-10">
                    <form>
                        <div className={"flex flex-col gap-4"}>
                            <img
                                src={Logo}
                                className="w-16 object-cover align-middle m-auto"
                                alt="loading logo"
                            />
                            <h2 className="text-white text-2xl lg:text-3xl font-normal mb-1 text-center">
                                Forgot <span className="font-bold">Password
                                </span>
                            </h2>
                            <p className="text-white text-sm font-normal text-center">
                                Please reset your account password
                            </p>
                        </div>

                        <div className="mt-8 mb-3">
                            <AuthInputFiled
                                error={touched.email && errors.email}
                                register={getFieldProps("email")}
                                label={"Email"} name={"email"}
                                placeholder={"Enter Register Email"}
                            />
                        </div>
                        <div className="flex justify-center items-center">
                            <AuthButton
                                disabled={isLoading}
                                name="Reset Password"
                                onClick={handleSubmit}
                                type={"submit"}
                                isLoading={isLoading}
                            />
                        </div>
                        <div
                            className="flex items-center justify-center text-white text-md mt-2 font-normal gap-1 text-center">
                            Back to
                            <Link onClick={handleToggle} className="hover:underline" to="/auth/login">
                                Login
                            </Link>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}

export default ForgotPassword
