const DefaultInput = ({
    required,
    label,
    labelExtra,
    type,
    position = false,
    placeholder,
    bg,
    icon,
    name,
    value,
    className,
    ref = null,
    error = null,
    disabled = false,
    bottom = null,
    onChange = () => null,
    handleCurrency = () => null,
    currency = null,
    isDisable = false
}) => {

    const handleChange = (value) => {
        onChange(name, value)
    }
   
    return (
        <div className={"flex flex-col gap-2 font-inter font-medium relative"}>
            {label &&
                <label className={"text-white text-sm font-medium flex gap-1 items-center"}>{label}
                    <span className={"text-xs text-white/50"}>{labelExtra}</span>
                </label>
            }
            {icon &&
                <div className={`absolute h-10 bg-black/20 ${bottom ? bottom : "bottom-0"}  ${position ? "right-0" : "left-0"} w-10 rounded-[2px]
                 justify-center items-center flex text-[20px] text-white dark:text-black`}>
                    {icon}
                </div>
            }
            {currency && (
                <select disabled={isDisable}
                    className={`bg-theme-5 absolute h-10 bg-black/20 ${bottom ? bottom : "bottom-0"}  ${position ? "right-0" : "left-0"} w-10 rounded-[2px]
                    justify-center items-center flex text-[14px] text-white dark:text-black`}
                    onChange={(e) => handleCurrency(e.target.value)}
                    defaultValue={currency}
                    value={currency}
                    name="currency"
                >
                    <option value="GBP">£</option>
                    <option value="USD">$</option>
                    <option value="EUR">€</option>
                </select>
            )}
            <input
                disabled={disabled}
                ref={ref}
                className={`w-full h-10 rounded-[5px] focus:outline-none border border-border
                 ${className ? className : "text-white"}  text-[14px]
                ${bg ? bg : "bg-theme-2 "} ${icon || currency ? "pl-12 pr-2" : "pl-2 pr-2"} ${error && !value ? "border border-[red]" : ""}`}
                type={type}
                name={name}
                value={value}
                onChange={(e) => handleChange(e.target.value)}
                placeholder={placeholder}
                required
            />
        </div>
    )
}
export default DefaultInput;
