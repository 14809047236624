"use client"
import React, { useContext, useEffect, useState } from 'react'
import Register from './components/Register'
import { useFormik } from "formik";
import Verification from '../components/Verification';
import Authentication from '../components/Authentication'
import { useNavigate } from 'react-router-dom';
import { UserContext } from '../../../context/user.context';
import toast from 'react-hot-toast';
import * as Yup from 'yup';
import { resendVerificationCode, verifyUserOtp } from '../../../service/userService';
import { signUp } from '../../../service/service.user';
import { redirectUser } from '../../../utils/Utils';

const createAccountValidation = Yup.object().shape({
	name: Yup.string().required('Name is required'),
	username: Yup.string().required('Username is required'),
	email: Yup.string().email('Invalid Email').required('Email is required'),
	password: Yup.string().min(8, 'Password must be of minimum 8 letters').required(),
	password2: Yup.string()
		.oneOf([Yup.ref('password'), null], 'Passwords must match')
		.required('Password is required'),
});
const RegisterPage = () => {
    const { login, user } = useContext(UserContext);
    const [isLoading, setIsLoading] = useState(false);
    const [customError, setCustomError] = useState({});
    const [userId, setUserId] = useState(0);
    const [timer, setTimer] = useState(0);
    const [showVerification, setShowVerification] = useState(false);
    const [email, setEmail] = useState("");
    const router = useNavigate()
    const queryParameters = new URLSearchParams(window.location.search);

    useEffect(() => {
        if (queryParameters.get("redirect")) {
            if (user.isAuthenticated) {
                localStorage.removeItem("redirect");
                const token = localStorage.getItem("token");
                window.location.href = `${queryParameters.get(
                    "redirect"
                )}/sso/${token}`;
            } else {
                localStorage.setItem("redirect", queryParameters.get("redirect"));
            }
        } else if (user.isAuthenticated) {
            redirectUser(router)
        }
    }, [user.isAuthenticated]);

    useEffect(() => {
        setShowVerification(false);
        setUserId(0);
        setTimer(0);
        setCustomError({});
    }, []);

    const formik = useFormik({
        initialValues: {
            username: "",
            name: "",
            email: "",
            password: "",
            password2: "",
            checked: false,
            // otp: '',
        },
        validationSchema: createAccountValidation,
        onSubmit: async (values) => {
            try {
                setCustomError({});
                setIsLoading(true);
                const response = await signUp(
                    queryParameters.get("r")
                        ? { ...values, referral_code: queryParameters.get("r") }
                        : values
                );

                if (
                    response.status === 200 &&
                    response.data &&
                    !response.data.error &&
                    response.data.id
                ) {
                    toast.success("Profile created successfully");
                    setUserId(response.data.id);
                    setEmail(values.email);
                    setShowVerification(true);
                    setIsLoading(false);
                } else if (response.data && response.data.error) {
                    const customError = {};
                    if (response.data.error.email)
                        errors.email = response.data.error.email[0];
                    if (response.data.error.name)
                        errors.name = response.data.error.name[0];
                    if (response.data.error.username)
                        errors.username = response.data.error.username[0];
                    if (response.data.error.password)
                        errors.password = response.data.error.password[0];
                    setCustomError(customError);
                    setIsLoading(false);
                }
            } catch (error) {
                console.log(error);
                toast.error(error.message);
                setIsLoading(false);
            }
        },
    });

    const { getFieldProps, handleSubmit, errors, touched } = formik;

    const submitVerification = async (verificationCode) => {
        setCustomError({})
        setIsLoading(true);
        await verifyUserOtp({
            verification_code: verificationCode,
            user_id: userId,
        })
            .then(async (res) => {
                if (res.status === 200 && res.data && res.data.user) {
                    toast.success("Verification completed!");
                    login(res.data);
                    redirectUser(router)
                    setShowVerification(false);
                    setIsLoading(false);
                } else if (res.data && res.data.error) {
                    setCustomError(res.data.error);
                    setIsLoading(false);
                }
            })
            .catch((err) => {
                console.log(err);
                setIsLoading(false);
            });
    };

    const startTimer = () => {
        const interval = setInterval(() => {
            setTimer(prevTimer => {
                if (prevTimer === 0) {
                    clearInterval(interval);
                    return 0;
                } else {
                    return prevTimer - 1;
                }
            });
        }, 1000);
    };

    const resendOtp = async () => {
        if (timer !== 0) {
            return;
        }
        await resendVerificationCode({ user_id: userId })
            .then((res) => {
                if (res.data && res.data.error) {
                    toast.error("Unable to Resend Code!")
                    return
                }
                toast.success("Code sent successfully!");
                setTimer(60);
                startTimer()
            })
            .catch((err) => {
                toast.error("Unable to Resend Code!");
                console.log(err);
            });
    };
    return (
        <Authentication>
            {!showVerification ? (
                <Register
                    touched={touched}
                    errors={errors}
                    getFieldProps={getFieldProps}
                    onSubmit={handleSubmit}
                    isLoading={isLoading}
                />
            ) : (
                <Verification
                    timer={timer}
                    error={customError.verification_code}
                    isLoading={isLoading}
                    submitVerification={submitVerification}
                    email={email}
                    resendOtp={resendOtp}
                />
            )}
        </Authentication>
    )
}

export default RegisterPage
