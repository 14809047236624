"use client"
import React, { useState } from 'react'
import OTPInput from 'react-otp-input'
import Logo from '../../../assets/images/creatorstock.svg'
// import Loading from '@/app/components/loading/loading'
import { Link } from 'react-router-dom'

const Verification = ({ resendOtp, timer = null, isLoading, error, submitVerification, email }) => {
    const [verificationCode, setVerificationCode] = useState("");
    return (
        <div className="[h-screen overflow-y-auto w-full relative bg-banner bg-cover h-screen bg-black " style={{ backgroundImage: "url('/images/banner.jpeg')" }}>
            <div className="flex justify-center items-center h-full">
                <div className="max-w-[500px] px-5 w-full py-10">
                    <form>
                        <div className={"flex flex-col gap-4"}>
                            <img
                                src={Logo}
                                className="w-16 object-cover align-middle m-auto"
                                alt="loading logo"
                            />
                            <h2 className="text-white text-2xl lg:text-3xl font-normal mb-1 text-center">
                                Account <span className="">Verification</span>
                            </h2>
                            <p className="text-white text-sm font-normal text-center">
                                Please enter the 6 digit code sent to "{email}"
                            </p>
                        </div>
                        <div className="my-3 flex justify-center items-center">
                            <OTPInput
                                value={verificationCode}
                                onChange={(value) => setVerificationCode(value)}
                                numInputs={6}
                                renderSeparator={
                                    <span style={{ marginRight: "10px" }} />
                                }
                                renderInput={(props) =>
                                    <input {...props}
                                        className='box-border h-12 rounded-sm px-4 m-0 text-white bg-white/20 !w-12 text-center focus:outline-none' />}
                            />
                        </div>
                        {error ?
                            <div className={`text-red-500 px-12 mb-2 text-sm`}>
                                {error}
                            </div>
                            :
                            null
                        }
                        <div className="flex justify-center items-center">
                            <button
                                onClick={() => submitVerification(verificationCode)}
                                className={`mb-5 mt-1 bg-white border-0 rounded-sm text-theme-1 cursor-pointer text-md h-12 px-5
                                ${isLoading || verificationCode.length !== 6 ? "opacity-60" : ""}`}
                                disabled={isLoading || verificationCode.length !== 6}
                            >
                                <span className="mr-1">Verify</span>
                                {isLoading &&
                                    "Loading"
                                    // <Loading color='black' size={30} />
                                }
                            </button>
                        </div>
                        <div
                            className="flex items-center justify-center text-white text-md mt-2 font-normal gap-1 text-center">
                            Didn’t receive code?
                            <Link onClick={timer ? null : resendOtp} className={!timer ? "hover:underline" : ""}
                                to="#">
                                {timer ? `Resend in ${timer}` : "Resend Code"}
                            </Link>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}

export default Verification
