import React from 'react';
import moment from 'moment';
import { IoEye } from 'react-icons/io5';
import { RiDeleteBin5Fill } from 'react-icons/ri';


const CollectionTable = ({ collections, openEditModal, openDeleteModal }) => {
    const links = [
        {
            href: '/', label: 'Edit', icon: <IoEye />, page: true,
            onClick: (data) => openEditModal(data), bg: "bg-indigo-500"
        },
        {
            href: '/', label: 'Delete', icon: <RiDeleteBin5Fill />, page: false,
            onClick: (id) => openDeleteModal(id),bg:"bg-red-500"
        },
    ]

    function capitalizeFirstLetter(str) {
        return str.charAt(0).toUpperCase() + str.slice(1);
    }

    return (
        <div className="w-full">
            <table className="min-w-full bg-transparent text-gray-100 text-left border border-theme-5">
                <thead>
                    <tr className={"bg-theme-5 text-[14px]"}>
                        <th className="border-0 p-4 font-medium">Name</th>
                        <th className="border-0 p-4 font-medium">Description</th>
                        <th className="border-0 p-4 font-medium">Created At</th>
                        <th className="border-0 p-4 font-medium">Amount</th>
                        <th className="border-0 p-4 font-medium">Actions</th>
                    </tr>
                </thead>
                <tbody className={"font-inter font-medium"}>
                    {collections.map((row, index) => (
                        <tr key={row.id} className={'border-b border-theme-5'}>
                            <td className="border-0 p-4">
                                <div className={"flex flex-row items-center gap-2"}>
                                    {/* <div className={"w-12 h-12 bg-theme-peach-100 rounded-md"}>
                                    </div> */}
                                    <div className={"flex flex-col gap-0"}>
                                        <span>{row?.name}</span>
                                    </div>
                                </div>
                            </td>

                            <td className="border-0 px-4 py-2">
                                {row.description}
                            </td>
                            <td className="border-0 px-4 py-2">
                                {moment(row.created_at).format("MM/DD/YYYY")}
                            </td>
                            <td className="border-0 px-4 py-2">{row?.price}</td>
                            <td className="border-0 px-4 py-2">
                                {/* <TableDropdown page={true} links={links} data={row} /> */}
                                <div className="flex items-center gap-2">
                                    {links?.map((item, index) => {
                                        return (
                                            <button
                                                className={`group flex items-center rounded-[5px] px-2 py-2 text-sm text-white 
                                                ${item.bg}`}
                                                onClick={(ev) => {
                                                    ev.stopPropagation()
                                                    item.onClick(item?.page ? row : row?.id)
                                                }}
                                            >
                                                {item.icon}
                                            </button>
                                        )
                                    })}
                                </div>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
};

export default CollectionTable;
