import { Dialog, Transition } from '@headlessui/react'
import { Fragment, useEffect, useState } from 'react'
import { IoClose } from 'react-icons/io5'
import { makePurchase } from '../../../../service/service.user'
import toast from '../../../../utils/toast'
import { MAIN_INTERFACE, SUCCESS200, SUCCESS201 } from '../../../../constants/constants'
import SwitchInput from '../../../main/components/inputs/SwitchInput'
import { MdInfoOutline } from 'react-icons/md'
import { BsCurrencyDollar, BsCurrencyEuro, BsCurrencyPound } from 'react-icons/bs'

export default function PurchaseModal({ isOpen, closeModal, id, folder }) {
    const [total, setTotal] = useState(0);
    const [adPrice, setAdPrice] = useState(false);
    const [rawPrice, setRawPrice] = useState(false);
    const [miscPrice, setMiscPrice] = useState(false);
    const [loading, setLoading] = useState(false);
    const [iconSet, setIconSet] = useState('₤');
    useEffect(() => {
        if (folder && folder?.usage_price) {
            const { usage_price, ad_price, ad_price_month, raw_price, misc } = folder;
            let total = Number.parseFloat(usage_price);

            if (adPrice && ad_price && ad_price_month) {
                total += Number.parseFloat((usage_price / 100) * ad_price * ad_price_month);
            }
            if (rawPrice && raw_price) {
                total += Number.parseFloat((usage_price / 100) * raw_price);
            }

            if (miscPrice && misc && misc.length > 0) {
                for (let data of misc) {
                    if (data && data.price > 0) {
                        total += Number.parseFloat(data.price);
                    }
                }
            }

            setTotal(total.toFixed(2));
        }
    }, [folder, adPrice, rawPrice, miscPrice]);

    const onBuy = () => {
        const data = {
            ad_usage: adPrice,
            raw_usage: rawPrice,
            misc_usage: miscPrice,
        };
        setLoading(true);
        makePurchase(id, data)
            .then((res) => {
                if (res && res.data && (res.data.status === SUCCESS200 || res.data.status === SUCCESS201)) {
                    window.location.href = res.data.uri;
                } else {
                    toast.error("Unable to make a purchase");
                    setLoading(false);
                }
            })
            .catch((err) => {
                toast.error(
                    err && err.response && err.response.data && err.response.data.message
                        ? err.response.data.message
                        : "Unable to make a purchase"
                );
                setLoading(false);
            });
    };

    const handleRedirect = () => {
        window.open(`${MAIN_INTERFACE}/terms`, '_blank'); // Opens the MAIN_INTERFACE in a new tab
    };

    useEffect(() => {
        setIconSet(folder.currency === 'EUR' ? '€' : folder.currency === 'GBP' ? '₤' : '$')
    }, [folder])
    

    return (
        <Transition appear show={isOpen} as={Fragment}>
            <Dialog as="div" className="relative z-10" onClose={closeModal} open={isOpen}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-black/25" />
                </Transition.Child>

                <div className="fixed inset-0 overflow-y-auto">
                    <div className="flex min-h-full items-center justify-center p-4 text-center">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 scale-95"
                            enterTo="opacity-100 scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 scale-100"
                            leaveTo="opacity-0 scale-95"
                        >
                            <Dialog.Panel
                                className="w-full max-w-md transform overflow-hidden rounded-2xl bg-theme-3 text-white p-6 text-left align-middle shadow-xl transition-all">
                                <Dialog.Title
                                    as="h3"
                                    className="text-lg font-medium leading-6 text-white mb-5"
                                >

                                    <div className='flex justify-between items-center'>
                                        <span>Purchase</span>
                                        <div className="flex gap-2 items-center">
                                            <MdInfoOutline className='cursor-pointer text-[20px]' onClick={handleRedirect} />
                                            <IoClose className='cursor-pointer text-[20px]' onClick={closeModal} />
                                        </div>
                                    </div>
                                </Dialog.Title>
                                <div className="mt-2 w-full">
                                    <div className="">
                                        <div className="flex justify-between items-center pb-4">
                                            <span className="fw-bold">Base Price: </span>
                                            <span>{iconSet}{folder?.usage_price}</span>
                                        </div>
                                        {folder?.enable_usage ?
                                            <CheckBox iconSet={iconSet} showInput={adPrice} text={`For ${folder.ad_price_month} months at ${folder.ad_price}% of Base Price`} price={Number.parseFloat((folder.usage_price / 100) * folder.ad_price * folder.ad_price_month)} name={"AD Usage"} onChange={() => setAdPrice(!adPrice)} /> : null}
                                        {folder?.raw_usage ?
                                            <CheckBox iconSet={iconSet} showInput={rawPrice} price={Number.parseFloat((folder.usage_price / 100) * folder.raw_price)} name={`Raw Footage`} onChange={() => setRawPrice(!rawPrice)} /> : null}
                                        {folder?.misc_usage ?
                                            <CheckBox iconSet={iconSet} showInput={miscPrice} price={null} name={"Miscellaneous:"} onChange={() => setMiscPrice(!miscPrice)} /> : null}

                                        {folder.misc_usage &&
                                            folder.misc &&
                                            folder.misc.length > 0 ? (
                                            <ul className="ml-[70px] text-sm list-disc border-t border-black/50">
                                                {folder.misc.map((misc, index) => (
                                                    <li key={index} className="flex justify-between items-center mt-1 text-[13px]">
                                                        <span className="">{misc.hook} : </span>
                                                        <span>{iconSet}{misc.price}</span>
                                                    </li>
                                                ))}
                                            </ul>
                                        ) : null}
                                    </div>
                                </div>
                                <div className={`mt-8 flex justify-between item-center`}>
                                    <span className="fw-bold">
                                        {iconSet}{total}
                                    </span>
                                    <div className='gap-3 flex'>
                                        <ModalButton
                                            disabled={loading}
                                            name={loading ? "Processing..." :
                                                "Buy Content"}
                                            onClick={onBuy}
                                        />
                                        <ModalButton
                                            disabled={false}
                                            name={"Cancel"}
                                            onClick={closeModal}
                                        />
                                    </div>

                                </div>
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition>
    )
}

const ModalButton = ({ onClick, disabled, name }) => {
    return (
        <button
            type="button"
            disabled={disabled}
            className={`inline-flex justify-center rounded-md px-4 py-2 text-sm font-medium focus:outline-none 
            focus-visible:ring-2 focus-visible:ring-transparent focus-visible:ring-offset-2 
            ${name === "Cancel" ? " bg-white hover:bg-white/90 text-theme-1" : " bg-theme-peach-200 hover:bg-theme-peach-100 text-white"}`}
            onClick={onClick}
        >
            {name}
        </button>
    )
}

const CheckBox = ({ name, onChange, text = "", price, showInput,iconSet }) => {
    return (<div className={"w-full text-[14px] text-white font-medium"}>
        <div className={"w-full h-10 flex justify-between items-center"}>
            <div className='flex justify-start gap-3'>
                <SwitchInput
                    onChange={onChange}
                    checked={showInput}
                />
                <span>{name}</span>
            </div>
            {price ?
                <span>{iconSet}{price}</span> : null}
        </div>
        {text}
    </div>)
}

