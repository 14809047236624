import { MdOutlineAccountBalanceWallet } from "react-icons/md";
import { GiCoins } from "react-icons/gi";

const EarningCard = ({ walletBalance }) => {
    return (
        <div className={"bg-theme-2 p-4 grid grid-cols-2 gap-5"}>
            <div className={"bg-theme-3 text-white flex flex-row justify-between items-start p-4 gap-4"}>
                <div className={"text-white flex flex-col gap-1"}>
                    <span className={"text-md text-white/70"}>Balance</span>
                    <span className={"text-xl"}>
                        £{parseFloat(walletBalance?.totalBalance).toFixed(2)}
                    </span>
                </div>
                <div className={""}>
                    <MdOutlineAccountBalanceWallet
                        className={"text-xl"}
                    />
                </div>
            </div>
            <div className={"bg-theme-3 text-white flex flex-row justify-between items-start p-4 gap-4"}>
                <div className={"text-white flex flex-col gap-1"}>
                    <span className={"text-md text-white/70"}>Lifetime earnings</span>
                    <span
                        className={"text-xl"}>
                        £{parseFloat(walletBalance?.walletLifetimeBalance).toFixed(2)}
                    </span>
                </div>
                <div className={""}>
                    <GiCoins
                        className={"text-xl"}
                    />
                </div>
            </div>
        </div>
    )
}
export default EarningCard;
