import React, {useRef} from 'react'
import DefaultInput from '../../../../components/inputs/DefaultInput'
import TextareaInput from '../../../../components/inputs/TextareaInput'
import defaultImage from '../../../../../../assets/images/user.jpg'
import MainLoader from '../../../../components/loading/MainLoader'
import {FaCamera} from 'react-icons/fa'
import Button from '../../../../components/buttons/Button'

const ProfileSection = ({
                            profilePicLoading,
                            user,
                            handleChange,
                            formData,
                            onSubmit,
                            handleImageChange,
                            isLoading
                        }) => {
    const imageUploadRef = useRef();

    const openImageUpload = () => {
        imageUploadRef.current.click();
    };

    const handleImageUpload = (e) => {
        handleImageChange(e.target.files[0]);
    };

    return (
        <div className={"p-4 "}>
            <div className={"w-[150px] h-[150px] bg-theme-5 rounded-md mb-4"}>
                {profilePicLoading &&
                    <div className='relative'>
                        <div className='absolute inset-0 z-10 bg-theme-4'>
                            <div className='bg-black opacity-70 !text-black'>
                                <MainLoader height={150}/>
                            </div>
                        </div>
                    </div>
                }
                <div className="relative inline-block">
                    <img className='w-[150px] h-[150px] rounded-md object-cover'
                         onError={(e) => {
                             e.target.src = defaultImage;
                         }}
                         src={user?.profileImage || defaultImage}
                         alt={"Card image"}
                    />
                    {!profilePicLoading ?
                        <div onClick={() => openImageUpload()}
                             className={`absolute bottom-0 bg-theme-2 right-0 p-3 rounded-tl-[5px] rounded-br-none
                              rounded-tr-none rounded-bl-none`}>
                            <input
                                className='hidden'
                                type="file"
                                onChange={handleImageUpload}
                                hidden
                                accept="image/*"
                                ref={imageUploadRef}
                            />
                            <FaCamera className='text-xl text-white cursor-pointer'/>
                        </div>
                        :
                        null
                    }
                </div>

            </div>
            <div className={"grid grid-cols-1 md:grid-cols-2 gap-4"}>
                <DefaultInput
                    onChange={handleChange} name="name" value={formData.name} label={"Name"}
                    placeholder={"Enter Name"} bg={"bg-theme-3"}
                />
                <DefaultInput
                    disabled={true} onChange={handleChange} name="email" value={formData.email}
                    label={"Email"} placeholder={"Enter Email"} bg={"bg-theme-5"}
                />
                <DefaultInput
                    disabled={true} onChange={handleChange} name="username" value={formData.username}
                    label={"Username"}
                    labelExtra={"(only letters, numbers, and underscores)"}
                    placeholder={"Enter Username"}
                    bg={"bg-theme-5"}
                />
            </div>

            <div className='text-white text-md mt-5'>
                About
                <div className={"grid grid-cols-1 md:grid-cols-2 gap-4 mt-5"}>
                    <DefaultInput
                        onChange={handleChange} name="location" value={formData.location} label={"Location"}
                        placeholder={"Location"}
                    />
                    <DefaultInput
                        onChange={handleChange} name="personal_website" value={formData.personal_website}
                        label={"Personal Site/Portfolio"} placeholder={"Personal Site/Portfolio"}
                    />

                    <div className="col-span-1 md:col-span-2">
                        <TextareaInput
                            show={true} onChange={handleChange} label={"Bio"} name="bio" value={formData.bio}
                            placeholder={"Enter Bio"}
                        />
                    </div>

                    <DefaultInput
                        onChange={handleChange} name="facebook" value={formData.facebook}
                        label={"Facebook Link"} placeholder={"Enter Facebook Id"}
                    />
                    <DefaultInput
                        onChange={handleChange} name="instagram" value={formData.instagram}
                        label={"Instagram Link"} placeholder={"Enter Instagram Id"}
                    />
                    <DefaultInput
                        onChange={handleChange} name="snapchat" value={formData.snapchat}
                        label={"Snapchat Link"} placeholder={"Enter Snapchat Id"}
                    />
                    <DefaultInput
                        onChange={handleChange} name="tiktok " value={formData.tiktok} label={"Tiktok Link"}
                        placeholder={"Enter Tiktok Id"}
                    />
                    <DefaultInput
                        onChange={handleChange} name="twitter" value={formData.twitter} label={"Twitter Link"}
                        placeholder={"Enter Twitter Id"}
                    />
                </div>
            </div>
            <div className='mt-5'>
                <Button onClick={() => onSubmit()} name={isLoading ? "Updating..." : "Update"}/>
            </div>
        </div>
    )
}

export default ProfileSection
