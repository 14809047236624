import React, { Fragment } from 'react';
import moment from 'moment';

const getBadge = (transaction) => {
    if (transaction.status === 2 || transaction.status === 3) {
        return (
            <Fragment>
                <div className={`text-[12px] px-2 h-6 flex items-center rounded-sm bg-red-400`}>
                    Rejected / Cancelled
                </div>
            </Fragment>
        );
    } else if (transaction.status === 0) {
        return (
            <Fragment>
                <div className={`text-[12px] px-2 h-6 flex items-center rounded-sm bg-[#ffc107]`}>
                    Pending
                </div>
            </Fragment>
        );
    } else if (transaction.status === 1) {
        return (
            <Fragment>
                <div className={`text-[12px] px-2 h-6 flex items-center rounded-sm bg-green-400`}>
                    Completed
                </div>
            </Fragment>
        );
    }
};

const EarningTable = ({ transactions }) => {
    return (
        <div className="w-full">
            <table className="min-w-full bg-transparent text-gray-100 text-left border border-theme-5">
                <thead>
                    <tr className={"bg-theme-5 text-sm"}>
                        <th className="border-0 p-4 font-medium">Date</th>
                        <th className="border-0 p-4 font-medium">Type</th>
                        <th className="border-0 p-4 font-medium">Client</th>
                        <th className="border-0 p-4 font-medium">Status</th>
                        <th className="border-0 p-4 font-medium">Note</th>
                        <th className="border-0 p-4 font-medium">Amount</th>
                    </tr>
                </thead>
                <tbody className={"font-inter"}>
                    {transactions && transactions.length > 0 && transactions.map((content, index) => {
                        return <tr key={index} className={'border-b border-theme-5'}>
                            <td className="border-0 p-4">
                                <div className={"flex flex-col items-start gap-2"}>
                                    <b>{moment(content.created_at).format("MMM DD, YYYY hh:mm A")}</b>
                                    <p>
                                        ID : {content.transaction_uid}
                                    </p>
                                </div>
                            </td>
                            <td className="px-4 py-2">{content.transaction_type}</td>
                            <td className="px-4 py-2">
                                {content && content.client && content.client.name ? content.client.name : "N/A"}
                            </td>
                            <td className="border-0 px-4 py-2 flex items-center">
                                {getBadge(content)}
                            </td>
                            <td className="border-0 px-4 py-2">{content.status === 1 ? content.admin_note : content.rejection_reason}</td>
                            <td style={{ color: content.type === '0' ? "rgb(255 7 7)" : "rgb(34, 167, 20)" }} className="border-0 px-4 py-2">
                                £ {content.type === '0' ? '-' : ''}{parseFloat(content.amount).toFixed(2)}
                            </td>
                        </tr>
                    })}

                </tbody>
            </table>
        </div>
    );
};

export default EarningTable;
