import React, { useRef, useState, useEffect, useContext, Fragment, useMemo } from 'react'
import PageHeading from "../components/pageHeading/PageHeading";
import DataCards from "./components/DataCards";
import NoDataCard from "./components/NoDataCard";
import { archiveContent, deleteContent, getUserContent, restoreContent } from '../../../service/service.content';
import { UserContext } from '../../../context/user.context';
import { LIMIT, SUCCESS200, SUCCESS201 } from '../../../constants/constants';
import NoThumb from '../../../assets/images/no-thumb.jpg'
import moment from 'moment';
import { convertSize } from '../../../utils/Utils';
import Search from '../components/search/Search';
import toast from '../../../utils/toast';
import EditModal from '../components/modals/EditModal';
import CommonModal from '../components/modals/CommonModal';
import { downloadFile } from '../../../utils/Utils'
import { useNavigate } from 'react-router-dom';
import ViewContentModal from '../components/modals/ViewContentModal';
import LoadMore from '../components/loadMore/LoadMore';
import EmptyPage from '../components/emptyPage/EmptyPage';
import MainLoader from '../components/loading/MainLoader';
import NotFound from '../components/emptyPage/NotFound';
import SelectedItemsContainer from './components/SelectedItems';
import AddToCollectionModal from '../components/modals/AddToCollectionModal';
import { deleteBulkContent } from '../../../service/service.collection';
import DefaultSwitch from '../components/switch/defaultSwitch';

const Content = () => {
    const navigate = useNavigate();
    const [contents, setContents] = useState([]);
    const [deleting, setDeleting] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [pageLoading, setPageLoading] = useState(false);
    const [fetchNext, setFetchNext] = useState(false);
    const [page, setPage] = useState(0);
    const { user, logout } = useContext(UserContext);
    const [searchText, setSearchText] = useState("");
    const [deleteModal, setDeleteModal] = useState(false);
    const [uuid, setUuid] = useState("");
    const [showEdit, setShowEdit] = useState(false)
    const [archiveModal, setArchiveModal] = useState(false);
    const [viewContent, setViewContent] = useState(false);
    const [selectedItems, setSelectedItems] = useState([]);
    const [showCollection, setShowCollection] = useState(false);
    const [showBulkDelete, setShowBulkDelete] = useState(false);

    let timeoutId = useRef(null);
    const delay = 1000;

    const openCollectionModal = (id) => {
        setShowCollection(!showCollection)
    }

    const closeCollectionModal = () => {
        setShowCollection(!showCollection)
    }

    const openBulkDeleteModal = (id) => {
        setShowBulkDelete(!showBulkDelete)
    }

    const closeBulkDeleteModal = () => {
        setShowBulkDelete(!showBulkDelete)
    }

    const openViewContent = (id) => {
        setUuid(id)
        setViewContent(!viewContent)
    }

    const closeViewContent = () => {
        setViewContent(!viewContent)
    }

    const openArchiveModal = (id) => {
        setUuid(id)
        setArchiveModal(!archiveModal)
    }

    const closeArchieveModal = () => {
        setArchiveModal(!archiveModal)
    }

    const openEditModal = (id) => {
        setUuid(id);
        setShowEdit(!showEdit)
    }

    const closeEditModal = (e, load = false) => {
        setShowEdit(!showEdit)
        if (load) {
            loadFreshContent()
        }
    }

    const updateContents = (id, value = false) => {
        const updatedItems = contents.map(item => {
            if (item.id === id) {
                return { ...item, "isSelected": value ? false : !item.isSelected };
            }
            return item;
        });
        setContents(updatedItems);
    }

    const updateSetContent = (selectedItemsArr, value) => {
        if (!value && !selectedItems.length) return false
        return selectedItemsArr.some(selectedItem => selectedItem.id == value.uuid)
    }

    const handleSelectedItems = (data) => {
        if (!data.id) return
        const isExist = selectedItems.some((item) => item.id === data.id);
        if (isExist) {
            const updatedData = selectedItems.filter((item) => item.id !== data.id);
            setSelectedItems(updatedData);
        } else {
            setSelectedItems([...selectedItems, data]);
        }
        updateContents(data.id);
    }

    const handleDeleteSelectedItems = (data) => {
        const updatedData = selectedItems.filter((item) => item.id != data.id)
        setSelectedItems(updatedData);
        updateContents(data.id, true);
    }

    const handleResetSelected = () => {
        setSelectedItems([])
        setTimeout(() => {
            loadFreshContent();
        }, 1000);
    }

    const handleArchiveModal = () => {
        archiveContent(uuid)
            .then((res) => {
                if (res && res.data && (res.data.status === SUCCESS200 || res.data.status === SUCCESS201)) {
                    toast.success("Content archived successfully");
                    closeArchieveModal();
                    loadFreshContent();
                } else {
                    toast.error("Unable to archive Content");
                }
            })
            .catch((err) => console.log(err));
    }

    const closeDeleteModal = () => {
        setDeleteModal(false)
    }

    const openDeleteModal = (id) => {
        setUuid(id);
        setDeleteModal(!deleteModal)
    }

    const handleDeleteModal = () => {
        setDeleting(true);
        deleteContent(uuid)
            .then((res) => {
                if (res && res.data && (res.data.status === SUCCESS200 || res.data.status === SUCCESS201)) {
                    toast.success("Content deleted successfully");
                    closeDeleteModal()
                    loadFreshContent(true);
                } else {
                    toast.error("Unable to delete Content");
                }
            })
            .catch((err) => console.log(err))
            .finally(() => {
                setDeleting(false);
            });
    }

    const handleLoadMoreClick = () => {
        setPageLoading(true);
        loadContents(page);
    }

    const debounce = (func, delay) => {
        return (...args) => {
            clearTimeout(timeoutId.current);
            timeoutId.current = setTimeout(() => {
                func(...args);
            }, delay);
        };
    };

    const debouncedGetContent = debounce((value) => {
        loadContents(0, value)
    }, delay);

    const handleSearchText = (value) => {
        setIsLoading(true);
        setSearchText(value)
        debouncedGetContent(value)
    }

    useEffect(() => {
        if (!user.isAuthenticated) {
            logout();
        } else loadFreshContent();
    }, []);

    const loadFreshContent = (softLoad = false) => {
        setIsLoading(!softLoad);
        setPage(0);
        setFetchNext(false);
        loadContents(0);
    };

    const handleViewContent = (id) => {
        navigate(`/stock/content-manager/${id}`)
    }


    const handleBulkDelete = () => {
        setDeleting(true);
        const payload = selectedItems.map((item) => {
            return {
                id: item?.id,
                name: item.meta?.name
            }
        })
        deleteBulkContent({ "contents": payload })
            .then((res) => {
                if (res && res.data && (res.data.status === SUCCESS200 || res.data.status === SUCCESS201)) {
                    toast.success("Content deleted successfully");
                    closeBulkDeleteModal()
                    setSelectedItems([])
                    loadFreshContent(true);
                } else {
                    toast.error("Unable to delete Content");
                }
            })
            .catch((err) => console.log(err))
            .finally(() => {
                setDeleting(false);
            });
    }

    const loadContents = async (page, term = searchText) => {
        await getUserContent(user.uuid, {
            getArchived: true,
            offset: page ? page * LIMIT : page,
            limit: LIMIT,
            term: term
        })
            .then((res) => {
                if (
                    (res.status === SUCCESS200 || res.status === SUCCESS201) &&
                    res.data &&
                    res.data.status === SUCCESS200 &&
                    res.data.contents
                ) {
                    const newContent = res.data.contents.map((content) => {
                        return {
                            id: content.uuid,
                            isSelected: (selectedItems && selectedItems?.length) ? updateSetContent(selectedItems, content) : false,
                            meta: {
                                id: content.id,
                                name: content.caption || "No caption available",
                                image: content.thumbnail || NoThumb,
                                preview: content.preview || NoThumb,
                                file_type: content.file_type || null,
                                type: "file",
                                time: moment(content.created_at).format("hh:mm A"),
                                date: moment(content.created_at).format("MM/DD/YYYY"),
                                size: content.file_size ? convertSize(content.file_size) : convertSize(0),
                                rejectedReason: content.rejectedReason,
                                approved: content.approved,
                                processing:
                                    content.published === 5 || content.isRejected || content.deleted_at
                                        ? "#fa3e3e"
                                        : content.published === 1
                                            ? "#1d2d40"
                                            : "#fc723b",
                                published: content.published,
                                isRejected: content.isRejected,
                                deleted_at: content.deleted_at,
                            },
                        };
                    });
                    if (page === 0) {
                        setContents([...newContent]);
                    } else {
                        setContents([...contents.concat(newContent)]);
                    }
                    setPage(page + 1);
                    setFetchNext(true);
                }
                if (!(res.data && res.data.contents && res.data.contents.length === LIMIT)) {
                    setFetchNext(false);
                }
            })
            .catch((err) => console.log(err))
            .finally(() => {
                setIsLoading(false);
                setPageLoading(false);
            });
    };

    const restoreFile = (uuid) => {
        restoreContent(uuid)
            .then((res) => {
                if (res && res.data && (res.data.status === SUCCESS200 || res.data.status === SUCCESS201)) {
                    toast.success("Content restored successfully");
                    loadFreshContent();
                } else {
                    toast.error("Unable to restore Content");
                }
            })
            .catch((err) => console.log(err));
    };

    const [enabled, setEnabled] = useState(false);

    return (
        <Fragment>

            <div>
                <PageHeading
                    heading={"Content"}
                    profile
                />
                <Search
                    placeholder={"Search content (name & tags)"}
                    folderName={"Search Files"}
                    searchText={searchText}
                    handleSearchText={handleSearchText}
                />
                {selectedItems && selectedItems.length ? <div className={"p-4"}>
                    <SelectedItemsContainer
                        data={selectedItems}
                        handleDeleteSelectedItems={handleDeleteSelectedItems}
                        handleBulkDelete={openBulkDeleteModal}
                        handleAddtoCollection={openCollectionModal}
                    />
                </div> : null}
                {isLoading ? (
                    <MainLoader />
                ) :
                    <>
                        <div className={"p-4"}>
                            <div className={"flex justify-between items-center"}>
                                <span className={"text-white font-medium"}>Files</span>
                                <DefaultSwitch enabled={enabled} setEnabled={setEnabled} />
                            </div>
                            {contents && contents?.length ?
                                <DataCards
                                    downloadFile={downloadFile}
                                    deleteFile={openDeleteModal}
                                    editFile={openEditModal}
                                    archiveFile={openArchiveModal}
                                    restoreFile={restoreFile}
                                    contents={contents}
                                    viewContent={openViewContent}
                                    handleSelectedItems={handleSelectedItems}
                                    handleViewContent={handleViewContent}
                                    enabled={enabled}
                                />
                                : searchText ? <NotFound content={"No Result Found"} /> :
                                    <EmptyPage />
                            }
                        </div>
                        {contents?.length &&
                            <div className='my-5'>
                                <LoadMore
                                    fetchNext={fetchNext}
                                    pageLoading={pageLoading}
                                    handleClick={handleLoadMoreClick}
                                />
                            </div>}
                    </>}
            </div>
            {viewContent && <ViewContentModal
                id={uuid}
                isOpen={viewContent}
                closeModal={closeViewContent}
            />}
            {deleteModal && <CommonModal
                title={"Delete File"}
                message={"Deleting will permanently remove this file from system and cannot be recovered. Are you sure you want to continue?"}
                isOpen={deleteModal}
                btnText={deleting ? "Deleting..." : "Delete"}
                handleConfirm={handleDeleteModal}
                isActionLoading={deleting}
                closeModal={closeDeleteModal} />}
            {showBulkDelete && <CommonModal
                title={"Bulk Delete Selected Files"}
                message={"Deleting will permanently remove these files from the system, and they cannot be recovered. Are you sure you want to continue?"}
                isOpen={showBulkDelete}
                btnText={deleting ? "Deleting..." : "Delete"}
                handleConfirm={handleBulkDelete}
                isActionLoading={deleting}
                closeModal={closeBulkDeleteModal} />}
            {archiveModal && <CommonModal
                title={"Archive File"}
                message={"Are you sure you want to Archive this file?"}
                isOpen={archiveModal}
                btnText={"Archive"}
                handleConfirm={handleArchiveModal}
                closeModal={closeArchieveModal} />}
            {showEdit && <EditModal
                isOpen={showEdit}
                loadFreshContent={loadFreshContent}
                closeModal={closeEditModal}
                fileId={uuid} />}
            {showCollection && <AddToCollectionModal
                isOpen={showCollection}
                closeModal={closeCollectionModal}
                selectedItems={selectedItems}
                handleResetSelected={handleResetSelected}
                handleDeleteSelectedItems={handleDeleteSelectedItems}
            />}
        </Fragment>

    )
}

export default Content;
