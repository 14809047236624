import React from 'react';
// import Loading from '../loading/loading';

const AuthButton = ({ disabled, name, onClick, isLoading, type = "button" }) => {
    return (
        <button disabled={disabled} onClick={onClick} type={type} className="flex items-center justify-center bg-white hover:bg-white/90 border-[none] rounded-[4px] !text-black cursor-pointer
         text-[15px] h-12 px-[20px] py-[0] w-auto">
            <span className="mr-1">{name}</span> {isLoading && 
            // <Loading color='black' size={30} />
            "Loading..."
            }
        </button>
    );
};

export default AuthButton;
